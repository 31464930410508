import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { map, switchMap, catchError, tap, withLatestFrom, mergeMap, finalize } from 'rxjs/operators';
import { ActionTypes, loadUser, loadUserDishes } from './user.actions';
import * as fromActions from './user.actions';
// import { DateTime } from 'luxon';
import { FirebaseStorageService } from '../../../services/firebase-storage.service';
import { from, of } from 'rxjs';
import { setSelectedUser } from '../../../admin/store/admin/admin.actions';
import { UserApi } from '~core/api/user.api';
import { getUser } from './user.selectors';
import { showErrorMessage, showSuccessMessage } from '../core/core.actions';
import { LoadingMessages, LoadingService } from '../../../providers/loading.provider';
import { ToastMessages, ToastService } from '../../../providers/toast.provider';
import dayjs from 'dayjs'
import { fetchUserWeekly } from '../user-daily/user-daily.actions';


@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private fbStorageService: FirebaseStorageService,
    private store: Store<AppState>,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) { }

  /**
	 * Fetch user from Firebase store
	 */
  fetchUserEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_USER),
      switchMap(({userId})=>{
        return this.fbStorageService.getUserDocument(userId).pipe(
          mergeMap((user:any)=>{

            console.warn('== fbStorageService -- getUserDocument', userId);
            // debugger;
            {
              // const newDate = {
              //   'Sun': dayjs().day(0).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Mon': dayjs().day(1).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Tue': dayjs().day(2).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Wed': dayjs().day(3).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Thu': dayjs().day(4).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Fri': dayjs().day(5).subtract(7,'day').format('YYYY-MM-DD'), 
              //   'Sat': dayjs().day(6).subtract(7,'day').format('YYYY-MM-DD'), 
              // }
              // user.userWeekly = {
              //   ...user.userWeekly,
              //   [newDate['Sun']]: [...(user.userWeekly[0] || [])],
              //   [newDate['Mon']]: [...(user.userWeekly[1] || [])],
              //   [newDate['Tue']]: [...(user.userWeekly[2] || [])],
              //   [newDate['Wed']]: [...(user.userWeekly[3] || [])],
              //   [newDate['Thu']]: [...(user.userWeekly[4] || [])],
              //   [newDate['Fri']]: [...(user.userWeekly[5] || [])],
              //   [newDate['Sat']]: [...(user.userWeekly[6] || [])]
              // }
              // this.fbStorageService.updateUser(userId, user);
              // debugger;
            }
            return [
              loadUser({user}),
              fetchUserWeekly({user})
            ]
          }),
          catchError((error)=>{
            console.log('error', error);
            return of(showErrorMessage(error))
          })
        )
      })
    )
  );

  /**
	 * Fetch users list from Firebase store
	 */
  UserEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.ADD_USER_ITEM),
      switchMap((userData:any)=>{
        const { additionalUserInfo: { isNewUser, providerId }, user  } = userData
        
        // Create new User
        if(isNewUser){
          return this.fbStorageService.createUser(user);
        }

        // return this.fbStorageService.updateIngredient(user);
        // return this.fbStorageService.getUserDocument(user).pipe(
        //   map((user:any)=>{
        //     console.log('getUsersCollection', user);
        //     return setSelectedUser({user})
        //   }),
        //   catchError((error)=>{
        //     console.log('error', error);
        //     return of(setSelectedUser(error))
        //   })
        // )
      })
    ),
    { dispatch: false }
  );

  /**
	 * Update user state
	 */
  updateUserEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.HANDLE_USER_ITEM_UPDATE),
      tap(()=>{
        this.loadingService.presentLoading(LoadingMessages.USER_INFO_UPDATE);
      }),
      switchMap((userData:any)=>{
        userData['newUser'] = false;
        return from(this.fbStorageService.updateUser(userData.uid, userData)).pipe(
          tap((toastMessage:any)=>{
            this.loadingService.dismissLoading();
          }),
          map((toastMessage:any)=>{
            return showSuccessMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_SUCCESSFULLY})
          }),
          catchError((error)=>{
            this.loadingService.dismissLoading();
            console.log('HANDLE_USER_ITEM_UPDATE - error', error)
            return of(showErrorMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_FAILURE}))
          })
        )
      })
    ),
    // { dispatch: false }
  );


  /**
	 * Fetch user's personal dishes list from Firebase store
	 */
  fetchUserDishesEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_USER_DISHES),
      withLatestFrom(this.store.pipe(select(getUser))),
      switchMap(([ ,user ])=>{
        return this.fbStorageService.getUserDishes(user.id).pipe(
          map((userDishes:any = {})=>{
            const { dishes = {} } = userDishes;
            // const res = dishes.map((dish)=>{
            //   return { 
            //     id: dish.payload.doc.id,
            //     ...dish.payload.doc.data()
            //   }
            // })
            return loadUserDishes({ dishes: dishes })
          }),
          catchError((error)=>{
            console.error('fetchUserDishesEffect - error', error);
            return of(showErrorMessage(error))
          })
        )
      })
    )
  );

  /**
	 * Handle update user dish
	 */
  handleUserDishUpdate$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.HANDLE_USER_DISH_UPDATE),
      withLatestFrom(this.store.pipe(select(getUser))),
      switchMap(([{ dishes }, user]: [any, any])=>{
        debugger;
        return from(this.fbStorageService.updateUserDish(dishes, user.id)).pipe(
          tap((dishes:any)=>{
            console.log('handleUserDishUpdate - success', dishes);
            this.toastService.presentToast(ToastMessages.USER_DISH_UPDATED, 'My dishes', 'success');
          }),
          catchError((error)=>{
            console.log('handleUserDishUpdate - error', dishes);
            return of(showErrorMessage(error))
          }),
          finalize(()=>{
            // this.toastService.presentToast(ToastMessages.USER_DISH_ADDED, 'My dishes', 'success');
          })
        )
      })
    ),
    { dispatch: false }
  );

}
