import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppUserWeekly } from './user-weekly/user-weekly.component';
import { UserDailyCalories } from './user-daily-calories/user-daily-calories.component';
import { UserDailyStatusComponent } from './daily-status/user-daily-status.component';
import { TranslateModule } from '@ngx-translate/core';
import { CompleteTestService } from '../../providers/autocomplete.provider';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { PipesModule } from '../../pipes/pipes.module';
import { RemoveDishPopover } from './remove-dish-popover/remove-dish-popover.component';
import { WeeklyPlanComponent } from './weekly-plan/weekly-plan.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { IngredientSizeComponent } from './ingredient-size/ingredient-size.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuIngredientComponent } from './menu-ingredient/menu-ingredient.component';
import { MyDishesComponent } from './my-dishes/my-dishes.component';


export const components = [
    AppUserWeekly,
    UserDailyCalories,
    UserDailyStatusComponent,
    RemoveDishPopover,
    WeeklyPlanComponent,
    IngredientSizeComponent,
    MenuIngredientComponent,
    MyDishesComponent
];

@NgModule({
  declarations: [
    components
  ],
  imports: [
  IonicModule, 
    CommonModule,
    NgxChartsModule,
    TranslateModule,
    AutoCompleteModule,
    PipesModule,
    SortablejsModule,
    FormsModule,
        ReactiveFormsModule
  ],
  exports: [
    components
  ],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA,
  //   NO_ERRORS_SCHEMA
  // ],
  providers: [
    CompleteTestService
  ]
})
export class UiComponentsModule { }