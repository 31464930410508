import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AuthState, AuthActions } from '@xtream/firebase-ngrx-user-management';
import { UserApi } from '~core/api/user.api';
import { IngredientsApi } from '~core/api/ingredients.api';
import { DishesApi } from '~core/api/dishes.api';
import { Router } from '@angular/router';
import { AuthApi } from '~core/api/auth.api';
import dayjs from 'dayjs'
import { UserDailyApi } from '~core/api/user-daily.api';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private store: Store<AuthState>,
    private userApi: UserApi,
    private userDailyApi: UserDailyApi,
    private dishesApi: DishesApi,
    public router: Router,
    private navController: NavController
  ) {
    this.initializeApp();
    this.userApi.isAdmin$.subscribe(isAdmin =>{
      if(isAdmin){
        console.log('isAdminisAdminisAdminisAdmin', isAdmin);
        this.router.navigate(['admin'])
      }
    })
  }

  initializeApp() {
    this.store.dispatch(new AuthActions.GetUser());
    // this.ingredientsApi.onFetchIngredients();
    const currentDate = dayjs().format('YYYY-MM-DD');
    this.userDailyApi.setSelectedDate(currentDate);

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang('he');
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use('he');      
    });
  }

  onNavigate(path){
    // this.router.navigate([path])
    this.navController.navigateForward('/weekly-stats')
  }
}
