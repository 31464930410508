import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { Observable } from 'rxjs';
import { getUserState, AuthActions, CustomEmailHandlerActions, PasswordManagementActions, getAuthError, isAuthSuccess, isUserLogged } from '@xtream/firebase-ngrx-user-management';

@Injectable()
export class AuthApi {

  userState$: Observable<any> = this.store.pipe(select(getUserState));
  isUserLogged$: Observable<any> = this.store.pipe(select(isUserLogged));
  success$: Observable<any> = this.store.pipe(select(isAuthSuccess));
  error$: Observable<any> = this.store.pipe(select(getAuthError));

  constructor(
    private store: Store<AppState>
  ) { }

  onEmailSignIn(credentials: { email: string, password: string, rememberMe?: boolean }) {
    this.store.dispatch(new AuthActions.CredentialsLogin(credentials.email, credentials.password, credentials.rememberMe));
  }

  onGoogleSignIn(): void {
    this.store.dispatch(new AuthActions.GoogleLogin());
  }

  onFacebookSignIn(): void {
    this.store.dispatch(new AuthActions.FacebookLogin());
  }

  onSignOut() {
    this.store.dispatch(new AuthActions.Logout());
  }

  onResetPassword(credentials: { email: string }) {
    this.store.dispatch(new PasswordManagementActions.ResetPasswordRequest({ email: credentials.email, redirectUrl: 'https://wowdiet.co.il' }));
  }

}
