import { createReducer, on } from '@ngrx/store';
import * as fromActions from './dishes.actions';

export interface ICheckItem {
  id: string;
  type: string;
  status: string;
  number: string;
  date: string;
  amount: number;
}

export interface ICheckEntities {
  [key: string]: ICheckItem;
}

export interface IDateEntities {
  [key: string]: ICheckEntities;
}

export interface State {
  dishes: any;
}

const initialState: State = {
  dishes: {},
};

export const rootReducer = createReducer(
  initialState,
  
  on(fromActions.loadDishesList, (state, { dishes }) => {
    return { 
      ...state, 
      dishes: { 
        // ...state.dishes,
        ...dishes
      }
    }
  }),

  on(fromActions.loadDishItem, (state, { dish }) => {
    return { 
      ...state, 
      dishes: { 
        ...state.dishes,
        dish
      }
    }
  }),

  // on(ApiSuccess, (state, action) => ({ selectedMockData: action.data, error: null })),
)