import { Action, createAction, props } from '@ngrx/store';

/**
 * For each action in an action group, make a simple
 * enum object for all of this group's action types
 */
export enum ActionTypes {
  FETCH_DISHES_LIST = '[Dishes] FETCH_DISHES_LIST',
  LOAD_DISHES_LIST = '[Dishes] LOAD_DISHES_LIST',

  ADD_DISH_ITEM = '[Dishes] ADD_DISH_ITEM',
  LOAD_DISH_ITEM = '[Dishes] LOAD_DISH_ITEM',
  HANDLE_DISH_UPDATE = '[Dishes] HANDLE_DISH_UPDATE',
  UPDATE_DISH_ITEM = '[Dishes] UPDATE_DISH_ITEM',
  DISABLE_DISH_ITEM = '[Dishes] DISABLE_DISH_ITEM',
  DELETE_DISH_ITEM = '[Dishes] DELETE_DISH_ITEM',

  SEARCH_LIST = '[Dishes] SEARCH_LIST',
  UPDATE_RESULTS = '[Dishes] UPDATE_RESULTS',

  SET_SELECTED_DISH = '[Dishes] SET_SELECTED_DISH',
  FETCH_SELECTED_DISH = '[Dishes] FETCH_SELECTED_DISH',

  // FETCH_USER_DISHES = '[Dishes] FETCH_USER_DISHES',
  // LOAD_USER_DISHES = '[Dishes] LOAD_USER_DISHES',
}

/**
 * Every action in comprised of at least a type and an optinal
 * payload. Expressing actions as classes enables powerful type
 * checking in reducer functions.
 */
export const fetchDishesList = createAction(
  ActionTypes.FETCH_DISHES_LIST
);

export const loadDishesList = createAction(
  ActionTypes.LOAD_DISHES_LIST,
  props<{dishes: any}>()
);

export const addDishItem = createAction(
  ActionTypes.ADD_DISH_ITEM,
  props<{dish: any}>()
);

export const loadDishItem = createAction(
  ActionTypes.LOAD_DISH_ITEM,
  props<{dish: any}>()
);

export const handleDishItemUpdate = createAction(
  ActionTypes.HANDLE_DISH_UPDATE,
  props<{dish: any}>()
);

export const updateDishItem = createAction(
  ActionTypes.UPDATE_DISH_ITEM,
  props<{dish: any}>()
);

export const disableDishItem = createAction(
  ActionTypes.DISABLE_DISH_ITEM,
  props<{dish: any}>()
);

export const deleteDishItem = createAction(
  ActionTypes.DELETE_DISH_ITEM,
  props<{dish: any}>()
);

export const searchList = createAction(
  ActionTypes.SEARCH_LIST,
  props<{query: any}>()
);

export const updateResults = createAction(
  ActionTypes.UPDATE_RESULTS,
  props<{dishes: any}>()
);

export const setSelectedDish = createAction(
  ActionTypes.SET_SELECTED_DISH,
  props<{dish: any}>()
);

export const fetchSelectedItem = createAction(
  ActionTypes.FETCH_SELECTED_DISH,
  props<{dish: any}>()
);

// export const fetchUserDishes = createAction(
//   ActionTypes.FETCH_USER_DISHES,
//   props<{userId: string}>()
// );

// export const loadUserDishes = createAction(
//   ActionTypes.LOAD_USER_DISHES,
//   props<{dishes: any}>()
// );