import { NgModule } from "@angular/core"
import { LocalDatePipe } from './local-date/local-date.pipe'
import { DateOfWeekPipe } from './day-of-week/day-of-week.pipe';
import { CustomDatePipe } from './custom-date/custom-date.pipe';
import { FormatFileSizePipe } from './format-file-size/format-file-size.pipe';

const PIPES = [
  LocalDatePipe,
  DateOfWeekPipe,
  CustomDatePipe,
  FormatFileSizePipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  exports: [
    ...PIPES
  ]
})
export class PipesModule {}