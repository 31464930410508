import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateToFormat'
})
export class LocalDatePipe implements PipeTransform {

  transform(date: any, args?: any): any {  
    const res = DateTime.fromISO(date).toFormat(args);
    return res;
  }

}
