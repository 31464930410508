import { Component, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';

export interface Size {
  name?: string;
  amount?: number;
  type?: string;
  isChecked: boolean
}

@Component({
  selector: 'app-new-dish-add-name',
  // templateUrl: 'ingredient-size.page.html',
  styleUrls: ['new-dish-add-name.page.scss'],
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar class="ion-text-center2">
        <!-- <ion-title>
          <h2 class="auth-title">
            {{ 'Select Sizes' | translate }}
          </h2>
        </ion-title> -->
        <ion-buttons slot="end">
          <ion-button (click)="cancel()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="sign-in-content ion-align-self-auto">
      <h2>
        {{ 'Add new Dish name' | translate }}
      </h2>
      <form [formGroup]="newDishForm" style="padding: 0 25%">
        <ion-item lines="full">
          <!-- <ion-label position="floating">{{ 'Amount' | translate}}</ion-label> -->
          <ion-input formControlName="name" type="text" placeholder="{{ 'Enter new Name' | translate}}" min="2" required></ion-input>
        </ion-item>
      </form>

    </ion-content>
    <ion-footer>
      <ion-row>
        <ion-col>
          <ion-button class="sign-in-btn2" expand="block" [disabled]="!newDishForm.valid" (click)="save()">{{ 'Save sizes' | translate }}</ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="cancel-btn" expand="block" (click)="cancel()">{{ 'CANCEL' | translate }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  `
})
export class AddNewDishNameModal {
  newDishForm: FormGroup;

  @Input() modal: Components.IonModal;

  validation_messages = {
    'name': [
      { type: 'required', message: 'Name is required.' }
    ]
  };

  constructor(
    public angularFire: AngularFireAuth,
    public router: Router,
    private modalController: ModalController
  ) {
    this.newDishForm = new FormGroup({
      name: new FormControl('', Validators.required),
    })
  }

  dismiss(optionSelected?: { selectedType: any, amountSelected: number }) {
    this.modalController.dismiss(optionSelected);
  }

  cancel() {
    this.modalController.dismiss();
  }

  save() {
    this.dismiss({
      ...this.newDishForm.value
    });
  }

}
