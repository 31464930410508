import { Component, NgZone, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Components } from '@ionic/core';
import { AuthApi } from '~core/api/auth.api';
import { ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IngredientsApi } from '~core/api/ingredients.api';

export interface Size {
  name?: string;
  amount?: number;
  type?: string;
  isChecked: boolean
}

@Component({
  selector: 'add-new-ingredient-modal',
  // templateUrl: 'ingredient-size.page.html',
  styleUrls: ['add-new-ingredient.page.scss'],
  template: `
    <ion-header>
      <ion-toolbar class="ion-text-center">
        <ion-title class="auth-titlse">
          <!-- <h2 class="auth-title"> -->
            {{ 'בחר רכיב' | translate }}
          <!-- </h2> -->
        </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="sign-in-content ion-align-self-auto">
      <ion-searchbar class="ion-align-text-right" placeholder="חפש..." [(ngModel)]="filterTerm" animated="true"></ion-searchbar>
      
      <ion-list class="inputs-list" lines="full">

        <!-- ingredients: {{ ingredientsDataRows.length }} -->
        <ion-item *ngFor="let ingredient of ingredientsDataRows | filter:filterTerm" (click)="onIngredientSelect(ingredient)">
          <ion-thumbnail class="ion-margin-end">
            <img src="{{ ingredient.image?.imageUrl }}" onerror="this.onerror=null;this.src='./assets/images/default_dish.svg'"/>
          </ion-thumbnail>
          <ion-label>
            <!-- <ion-text color="primary">
              <h3>H3 Primary Title</h3>
            </ion-text> -->
            <p>
              <ion-text color="dark">{{ ingredient.name }}</ion-text><br/>
              <ion-text color="medium">{{ ingredient.shortDescription }}</ion-text>            
            </p>
            
            <!-- <ion-text color="secondary">
              <p>Paragraph line 2 secondary</p>
            </ion-text> -->
          </ion-label>
          <ion-icon></ion-icon>
        </ion-item>

      </ion-list>

    </ion-content>
  `
})
export class AddNewIngredientPage {
  signInForm: FormGroup;
  addNewDishForm: FormGroup;
  submitError: string;
  authRedirectResult: Subscription;

  filterTerm: string;
  ingredientsDataRows: any[];

  // @Input() modal: Components.IonModal;
  @Input() ingredients: any;
  // @Input() sizesArray: any[] = [];
  // @Input() sizeType: string;

  // @Input() onSaveSizes: Function;
  // @Output() onSaveSizes: EventEmitter<any> = new EventEmitter<any>();

  validation_messages = {
    'name': [
      { type: 'required', message: 'Name is required.' }
    ],
    'amount': [
      { type: 'required', message: 'Amount is required.' },
      { type: 'minlength', message: 'Amount must be greater than 0.' }
    ],
    'type': [
      { type: 'required', message: 'type is required.' },
    ]
  };

  constructor(
    public angularFire: AngularFireAuth,
    public router: Router,
    private ngZone: NgZone,
    private authApi: AuthApi,
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private ingredientsApi: IngredientsApi
  ) {
    this.addNewDishForm = new FormGroup({
      "name": new FormControl('', Validators.required),
      shortDescription: new FormControl(''),
      ingredients: new FormArray([], Validators.minLength(1))
    })
    this.ingredientsApi.ingredientsState$.subscribe((ingredientsState)=>{
      const { ingredients = {} } = ingredientsState;
      this.ingredientsDataRows = Object.values(ingredients);
      console.log('ingredientsDataRows', this.ingredientsDataRows.length)
			// this.ingredientsCollection = ingredients;
		  });
  }

  ionViewWillEnter() {
    // this.addNewDishForm = new FormGroup({
    //   "name": new FormControl('')
    // })
  }

  createSize(size: Size) {
     return new FormGroup({
      name: new FormControl(size.name),
      amount: new FormControl(size.amount),
      type: new FormControl(size.type),
      isChecked: new FormControl(size.isChecked)
    });
  }

  createArray(sizeArray: any[]): any[] {
    if (!sizeArray || sizeArray.length === 0) {
      return [
        this.createSize({isChecked: true})
      ]
    }
    const res =  sizeArray.map((size)=>{
      return this.createSize(size);
    })
    // debugger;
    return res;
  }

  addIngredient(){
    // const legnth = Object.values(this.addNewDishForm.value).length;

    this.addNewDishForm.get('ingredients')['controls'].push(new FormGroup({
        name: new FormControl(''),
        amount: new FormControl(''),
        type: new FormControl('')
      }
    ));
  }

  dismiss() {
    this.modalController.dismiss();
  }

  saveNewDish() {
    const res = this.addNewDishForm.value;
    // this.onSaveSizes({size: res});
    this.dismiss();
  }

  onIngredientSelect(ingredient: any) {
    this.modalController.dismiss({ingredient: { ...ingredient }});
  }

}
