import * as fromIngredients from './ingredients';
import * as fromDishes from './dishes';
import * as fromUser from './user';
import * as fromUserDaily from './user-daily';
import * as fromCore from './core';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  core: fromCore.State,
  user: fromUser.State,
  userDaily: fromUserDaily.State,
  ingredients: fromIngredients.State
  dishes: fromDishes.State
}

export const AppReducers: ActionReducerMap<AppState> = {
  core: fromCore.rootReducer,
  user: fromUser.rootReducer,
  userDaily: fromUserDaily.rootReducer,
  ingredients: fromIngredients.rootReducer,
  dishes: fromDishes.rootReducer,
}