import { Component, NgZone, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Components } from '@ionic/core';
import { AuthApi } from '~core/api/auth.api';
import { ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isUserLogged } from '@xtream/firebase-ngrx-user-management';

export interface Size {
  name?: string;
  amount?: number;
  type?: string;
  isChecked: boolean
}

@Component({
  selector: 'app-ingredient-amount-selection-modal',
  // templateUrl: 'ingredient-size.page.html',
  styleUrls: ['ingredient-amount-selection.page.scss'],
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar class="ion-text-center2">
        <!-- <ion-title>
          <h2 class="auth-title">
            {{ 'Select Sizes' | translate }}
          </h2>
        </ion-title> -->
        <ion-buttons slot="end">
          <ion-button (click)="cancel()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="sign-in-content ion-align-self-auto">
      <h2>
        {{ 'Select Sizes' | translate }}
      </h2>
      <form [formGroup]="ingredientAmountForm" style="padding: 0 25%">
        <ion-item lines="full">
          <!-- <ion-label position="floating">{{ 'Amount' | translate}}</ion-label> -->
          <ion-input [disabled]="ingredientAmountForm.controls.selectedType.value.name !== 'custom' "  formControlName="amountSelected" type="number" placeholder="{{ 'Add Amount' | translate}}" (ionChange)="updateVal()" min="1" required></ion-input>
        </ion-item>
        <ion-select formControlName="selectedType" (ionChange)="updateValue($event.target.value)" value="{{custom}}" color="light" class="portion-size-select ion-text-start" okText="Okay" icon="add" placeholder="{{ 'Portion Size' | translate }}" cancelText="Dismiss" interface="popover">
        <!-- <ion-select-option value="size">{{ size.name | translate}}</ion-select-option> -->
          <ion-select-option [value]="custom">{{ 'custom'| translate}}</ion-select-option>
          <ion-select-option *ngFor="let size of moreSizes" [value]="size">{{ size.name | translate}}</ion-select-option>
        </ion-select>
      </form>

    </ion-content>
    <ion-footer>
      <ion-row>
        <ion-col>
          <ion-button class="sign-in-btn2" expand="block" [disabled]="!ingredientAmountForm.valid" (click)="save()">{{ 'Save sizes' | translate }}</ion-button>
        </ion-col>
        <ion-col>
          <ion-button class="cancel-btn" expand="block" (click)="cancel()">{{ 'CANCEL' | translate }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  `
})
export class IngredientAmountSelectionModal {
  // signInForm: FormGroup;
  ingredientAmountForm: FormGroup;
  submitError: string;
  authRedirectResult: Subscription;

  moreSizesArray: any;

  @Input() modal: Components.IonModal;
  @Input() moreSizes: any

  @Input() onSaveSizes: Function;
  // @Output() onSaveSizes: EventEmitter<any> = new EventEmitter<any>();

  validation_messages = {
    'name': [
      { type: 'required', message: 'Name is required.' }
    ],
    'amount': [
      { type: 'required', message: 'Amount is required.' },
      { type: 'minlength', message: 'Amount must be greater than 0.' }
    ],
    'type': [
      { type: 'required', message: 'type is required.' },
    ]
  };

  custom: any = {
    name: 'custom'
  }

  constructor(
    public angularFire: AngularFireAuth,
    public router: Router,
    private ngZone: NgZone,
    private authApi: AuthApi,
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {
    this.ingredientAmountForm = new FormGroup({
      selectedType: new FormControl({ name: 'custom' }),
      amountSelected: new FormControl('', Validators.min(1))
    })
  }

  dismiss(optionSelected?: { selectedType: any, amountSelected: number }) {
    this.modalController.dismiss(optionSelected);
  }

  updateVal() {
  }

  updateValue(value) {
    // debugger;
    this.ingredientAmountForm.controls['amountSelected'].setValue(value.amount);
  }

  cancel() {
    this.modalController.dismiss();
  }

  save() {
    const { 
      selectedType: { name } = { name: 'custom' },
      amountSelected = 0
     } = this.ingredientAmountForm.value;
    // debugger;
    // this.onSaveSizes({size: res});

    this.dismiss({
      selectedType: name,
      amountSelected
    });
  }

}
