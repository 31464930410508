import {Injectable} from '@angular/core';
import { filter, map, concatMap, withLatestFrom } from 'rxjs/operators';

// import {HttpClient} from '@angular/common/http';
import { DishesApi } from '../core/api/dishes.api';

import {AutoCompleteService} from 'ionic4-auto-complete';
import { Observable } from 'rxjs';
import { IngredientsApi } from '../core/api/ingredients.api';
import { UserApi } from '~core/api/user.api';

@Injectable()
export class CompleteTestService implements AutoCompleteService {
  labelAttribute = 'name';
  formValueAttribute = 'numericCode';

  dishes$: Observable<any> = this.dishesApi.getDishes$;
  ingredients$: Observable<any> = this.ingredientsApi.ingredients$;
  myDishes$: Observable<any> = this.userApi.myDishes$;

  constructor(
     private userApi: UserApi,
     private ingredientsApi: IngredientsApi,
     private dishesApi: DishesApi
  ) {
     this.getResults('');
  }

  getResults(keyword:string) {
   //   if (!keyword) { return false; }
      keyword = typeof keyword === 'string' ? keyword : '';
      console.log('keyword', keyword);

      const ingredientsAsDishes = this.ingredients$.pipe(map((ingredients)=>{
            let array = Object.values(ingredients).filter((ingredient: any) => {
               // return ingredient.isDish === 'dish'
               return !ingredient.isHide
            })
            // console.log('ingredientsAsDishes', Object.values(ingredients).length, array.length);
            array = array.map((ingredient:any)=>{
               // console.log('ingredient.', ingredient);
               // console.log('ingredient.', ingredient.selectedAmount === 'portion');
               return {
                  id: ingredient.id,
                  name: ingredient.name,
                  ingredients: [ingredient],
                  image: {
                     imageUrl: ingredient.image ? ingredient.image?.imageUrl : (ingredient?.imageUrl ? ingredient?.imageUrl : null)
                  },
                  isIngredientAsDish: true, //ingredient.size !== 'portion'
                  isIngredientAsPortion: ingredient.size === 'portion'
                  // isIngredientAsDish: ingredient.isDish === 'dish'
               }
            })

            return array;
         }
      ))

      const myDishes = this.myDishes$.pipe(map((myDishes)=>{
         const array = Object.values(myDishes).map((dish:any)=>{
            return {
               // id: dish.id,
               name: dish.name,
               ingredients: dish.ingredients,
               image: {
                  imageUrl: dish.image ? dish.image?.imageUrl : (dish?.imageUrl ? dish?.imageUrl : null)
               },
               isMyDish: true,
               dishType: 'MY_DISH'
            }
         })
         return (array || []);
      }
   ))

      return this.dishes$.pipe(
         withLatestFrom(ingredientsAsDishes, myDishes),
         map(
         ([result, ingredients, myDishes=[]]) => {

            result = result.filter((dish: any) => {
               return !dish.isHideDish
            });

            result = [].concat(myDishes,result,ingredients);

            // result = Object.values(result);
            const res = result.filter(
               (item) => {
                  const keywords = keyword.split(' ');

                  // console.log('keywords', keywords.length);

                  // console.log('keyword', keywords);

                  const res1 = keyword.split(' ').some((keyword1, index) => {
                     return keyword1 && item.name.toLowerCase().includes(keyword1)
                  });
                  const res2 = item.name.toLowerCase().includes(keyword.toLowerCase());
                  const res3 = item.name.toLowerCase().split(' ').some((itemWord, index) => {
                     return itemWord && keyword.toLowerCase().includes(itemWord)
                  });

                  // console.log('keyword___', item.name, res1, res2, res3);

                  // if(keywords.length > 1) {
                     return keyword.split(' ').some((keyword1, index) => {
                        return keyword1 && item.name.toLowerCase().includes(keyword1)
                     })
                  // }
                  // else {
                  //    return item.name.toLowerCase().includes(
                  //    keyword.toLowerCase()
                  // );
                  // }
               }
            );
            
            return res.sort((a,b)=>{

               let a1Counter = 0;
               const resA1 = keyword.split(' ').some((keyword1, index) => {
                  return keyword1 && a.name.toLowerCase().includes(keyword1)
               });
               const resA3 = a.name.toLowerCase().split(' ').some((itemWord, index) => {
                  return itemWord && keyword.toLowerCase().includes(itemWord)
               });
               const resA2 = a.name.toLowerCase().includes(keyword.toLowerCase());
               if(resA1) a1Counter++;
               if(resA2) a1Counter++;
               if(resA3) a1Counter++;

               let b1Counter = 0;
               const resB1 = keyword.split(' ').some((keyword1, index) => {
                  return keyword1 &&b.name.toLowerCase().includes(keyword1)
               });
               const resB3 = b.name.toLowerCase().split(' ').some((itemWord, index) => {
                  return itemWord && keyword.toLowerCase().includes(itemWord)
               });
               const resB2 = b.name.toLowerCase().includes(keyword.toLowerCase());
               if(resB1) b1Counter++;
               if(resB2) b1Counter++;
               if(resB3) b1Counter++;

               return b1Counter-a1Counter;
            })
         }
      ));
   //   }));
  }
}
