import { Component, Input, OnInit } from '@angular/core';
import { Components } from '@ionic/core';

@Component({
  selector: 'app-auth-base-modal',
  templateUrl: 'auth.page.html',
  styleUrls: ['auth.page.scss'],
})
export class AuthBaseModal implements OnInit {
  rootPage: any;

  @Input() modal: Components.IonModal;

  @Input() showSignUpModal: Function;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Enter a valid email.' }
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 6 characters long.' }
    ]
  };

  constructor(
    // private nav: IonNav
  ) { }

  ngOnInit(): void {
    // this.nav.push(SignInPage, { nav: this.nav }); // MyFirstPage is a class of an Angular component
  }

}
