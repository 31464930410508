import { Component, ChangeDetectionStrategy, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export enum SizeType {
	GRAM = 'gram',
	ML = 'ml',
}

export interface SizeData {
	name: string;
	anmount: number,
  type: SizeType,
  isDefault: boolean
}

export enum NutritionType {
	PROTEIN = 'protein',
	CARBS = 'carbs',
	FATS ='fats'
}

@Component({
  selector: 'app-menu-ingredient',
  styleUrls: ['menu-ingredient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <!-- {{ ingredientSelectedSizeForm.value |json }} -->
      <form [formGroup]="ingredientSelectedSizeForm" (change)="onValueChange()">
        <!-- {{ ingredientSelectedSizeForm.value | json }} -->
        <ion-row>
          <ion-col size="10">
            <ion-label><ion-text color="dark">{{ ingredient?.name }}</ion-text></ion-label>
          </ion-col>
          <ion-col size="2">
            <ng-content></ng-content>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <!-- <ion-select disabled color="light" [value]="sizeType" class="portion-size-select" okText="Okay" icon="add" placeholder="{{ 'Portion Size' | translate }}" cancelText="Dismiss" interface="popover">
              <ion-select-option value="100 Gram">{{SizeType.GRAM | translate}}</ion-select-option>
              <ion-select-option [value]="SizeType.ML">{{SizeType.ML | translate}}</ion-select-option>
            </ion-select> -->
            <!-- moreSizes {{ ingredient.moreSizes | json }} -->
            <!-- {{ { amount: ingredient.selectedSize?.size?.amountSelected, name: ingredient.selectedSize?.size?.selectedType, type: "" } | json }} -->
            <!-- [value]="ingredientSelectedSizeForm.value?.ingredient?.size" -->
            <!-- {{ ingredientSelectedSizeForm.controls.selectedSize?.value | json }} -->
            <ion-select formControlName="selectedSize" (ionChange)="updateDishMenuValue($event.target.value)" [compareWith]="compareWithFn" color="light" class="portion-size-select" okText="Okay" icon="add" placeholder="{{ 'Portion Size' | translate }}" cancelText="Dismiss" interface="action-sheet" style="max-width:100%" disabled="{{ ingredientSelectedSizeForm.controls.selectedSize?.value.amount === 'portion'}}">
              <ion-select-option *ngIf="ingredientSelectedSizeForm.controls.selectedSize?.value.amount === 'portion'" [value]="portion">{{ 'portion'| translate}}</ion-select-option>
              <ion-select-option *ngIf="ingredientSelectedSizeForm.controls.selectedSize?.value.amount !== 'portion'" [value]="custom">{{ 'custom'| translate}}</ion-select-option>
              <ion-select-option *ngFor="let size of ingredient.moreSizes" [value]="size">{{ size.name  }}</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size="6">

            <!-- {{ ingredientSelectedSizeForm.controls.selectedSize?.value.name !== 'custom' }} -->
            <input *ngIf="ingredientSelectedSizeForm.controls.selectedSize?.value.amount === 'portion'" class="input-menu-ingredient-amount" type="number" placeholder="{{ 1 ? showAmoutWithSizeType(ingredientSelectedSizeForm.controls.selectedSize?.value.amount) : 'או הזן כמות' }}" [value]="showAmoutWithSizeType(ingredientSelectedSizeForm.controls.selectedSize?.value.amount)" [readOnly]="ingredientSelectedSizeForm.controls.selectedSize?.value.name !== 'custom'" min="1" required/>
            <!-- {{ ingredientSelectedSizeForm.controls.selectedSize?.value | json }} -->
            <input *ngIf="ingredientSelectedSizeForm.controls.selectedSize?.value.amount !== 'portion'" class="input-menu-ingredient-amount" formControlName="selectedAmount" type="number" (change)="onValueChange($event)" (input)="onValueChange($event)" placeholder="{{ 'או הזן כמות' }}" [value]="ingredientSelectedSizeForm.controls.selectedSize?.value.amount" [readOnly]="ingredientSelectedSizeForm.controls.selectedSize?.value.name !== 'custom'" min="1" required/>
          </ion-col>

        </ion-row>
  
        <hr class="ion-no-margin ion-margin-top" style="background-color: lightgray;"/>
      </form>
    </div>
  `
})
export class MenuIngredientComponent implements OnInit {
  SizeType = SizeType;
  sizeForm: FormGroup;
  custom: any = {
    name: 'custom'
  }
  portion: any = {
    name: 'portion',
    amount: 'portion'
  }

  @Input() sizeType: string;
  // @Input() formGroup2: FormGroup;
  @Input() ingredient: any;

  @Input() ingredientSelectedSizeForm: FormGroup;
  // @Input() set formGroup(_sizeData){
  //   this.sizeForm = new FormGroup({
  //     name: new FormControl(''),
  //     amount: new FormControl(''),
  //     type: new FormControl('')
  //   }
  // }

  @Input() onReset: Function;
  @Input() onRemove: Function;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('myForm') myForm;

  constructor(
    public popoverController: PopoverController,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // this.nav.push(SignInPage, { nav: this.nav }); // MyFirstPage is a class of an Angular component
  }

  onDishReset(){
    this.onReset();
    this.close()
  }

  onDishRemove(){
    this.onRemove();
    this.close()
  }

  onValueChange(item){

    // debugger;

    this.onChange.emit();

    // this.formGroup2.controls.name.setValue('asdasds');
    // debugger
  }

  updateDishMenuValue(updateSize) {
    // const selecteSize = {
      // amountSelected: updateSize.amount,
      // selectedType: updateSize.name
    // }
    this.ingredientSelectedSizeForm.controls.selectedAmount.setValue(updateSize.amount);

    this.onChange.emit();
  }

  close() {
    this.popoverController.dismiss();
  }

  compareWithFn = (o1, o2) => {
    const res = o1 && o2 ? o1.name === o2.name && (o1.amount === o2.amount || o1.amount === undefined) : o1 === o2;
    console.log('compareWithFn', o1, o2, res);
    return o1 && o2 ? o1.name === o2.name && (o1.amount === o2.amount || o1.amount === undefined) : o1 === o2;
  };

  showAmoutWithSizeType(amount){
    return `${this.translateService.instant(amount)}`; //${this.sizeType}`;
  }

}
