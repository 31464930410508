import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { State, IDateEntities } from './user-daily.reducer';
import { DateTime } from 'luxon';
import dayjs from 'dayjs'

export const getUserDailyState = (state: AppState) => {
    return state.userDaily
};
export const getSelectedDate = createSelector(
    getUserDailyState,
    (state: State) => state.selectedDateId
)
export const getUserDaily = createSelector(
    getUserDailyState,
    (state: State) => state.userDaily
)
export const getUser = createSelector(
    getUserDailyState,
    (state: State) => { 
        if(state.selectedUser) {
            return state.selectedUser
        }
        {
            return state.user
        }
    }
)