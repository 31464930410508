import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavController } from '@ionic/angular';



@Component({
  selector: 'app-settings-popover',
  templateUrl: './settings.popover.html',
  styleUrls: ['./settings.popover.css'],
})
export class SettingsPopover implements OnInit {

  @Input() onSignOut: Function;

  constructor(
    public afAuth: AngularFireAuth,
    private navController: NavController,
  ) {
  }

  ngOnInit() {
  }

  signOut(){
    this.onSignOut();
  }

  
}
