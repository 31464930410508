import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AuthBaseModal } from '../modals/auth/auth-modal/auth.page';
import { SettingsPopover } from './settings-popover/settings.popover';
import { UiComponentsModule } from './components/components.module';
import { ToastService } from '../providers/toast.provider';
import { LoadingService } from '../providers/loading.provider';

@NgModule({
  declarations: [
    AuthBaseModal,
    SettingsPopover
  ],
  imports: [
    CommonModule, 
    IonicModule,
    UiComponentsModule
  ],
  exports: [
    AuthBaseModal,
    SettingsPopover,
    UiComponentsModule
  ],
  providers: [
    ToastService,
    LoadingService
  ]
})
export class SharedModule { }
