import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { State, IDateEntities } from './ingredients.reducer';
// import { DateTime } from 'luxon';

export const getIngredientsState = (state: AppState) => {
    return state.ingredients
};
export const getIngredients = createSelector(
    getIngredientsState,
    (state: State) => state.ingredients
)
// export const getChecksList = createSelector(
//   getChecks,
//   (state: State) => Object.values(state.checksCollection)
// )