import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export enum NutritionType {
	PROTEIN = 'protein',
	CARBS = 'carbs',
	FATS ='fats'
}

@Component({
  selector: 'remove-dish-popover',
  styleUrls: ['remove-dish-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ion-list>
      <ion-item *ngIf="!hideReset" (click)="onDishReset()">
        <ion-label>{{'Reset Dish' | translate}}</ion-label>
        <ion-icon slot="end" name="refresh-outline"></ion-icon>
      </ion-item>
      <ion-item (click)="onDishRemove()">
        <ion-label color="danger">{{'Remove Dish' | translate}}</ion-label>
        <ion-icon slot="end" color="danger" name="trash-outline"></ion-icon>
      </ion-item>
    </ion-list>
  `
})
export class RemoveDishPopover {

  @Input() hideReset: boolean;
  @Input() onReset: Function;
  @Input() onRemove: Function;

  constructor(public popoverController: PopoverController) {}

  onDishReset(){
    this.onReset();
    this.close()
  }

  onDishRemove(){
    this.onRemove();
    this.close()
  }

  close() {
    this.popoverController.dismiss();
  }
}
