import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () => import('./modals/auth/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./modals/auth/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modals/auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'add-new-dish',
    loadChildren: () => import('./modals/add-new-dish/add-new-dish.module').then( m => m.AddNewDishModule)
  },
  {
    path: 'add-new-ingredient',
    loadChildren: () => import('./modals/add-new-ingredient/add-new-ingredient.module').then( m => m.AddNewIngredientModule)
  },
  {
    path: 'add-new-dish-name',
    loadChildren: () => import('./modals/new-dish-add-name/new-dish-add-name.module').then( m => m.IngredientAmountSelectionModalModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  { 
    path: 'weekly-stats', 
    loadChildren: () => import('./pages/weekly-stats/weekly-stats.module').then( m => m.WeeklyStatsPageModule)
  },
  { 
    path: 'daily-stats', 
    loadChildren: () => import('./pages/daily-stats/daily-stats.module').then( m => m.DailyStatsModule)
  },
  { 
    path: 'my-dishes', 
    loadChildren: () => import('./pages/my-dishes/my-dishes.module').then( m => m.MyDishesModule)
  },
  { path: '', redirectTo: 'daily-stats', pathMatch: 'full' }
];

@NgModule({
  imports: [
  RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
