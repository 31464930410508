import { Action, createAction, props } from '@ngrx/store';

/**
 * For each action in an action group, make a simple
 * enum object for all of this group's action types
 */
export enum ActionTypes {
  ADD_USER_ITEM = '[User] ADD_USER_ITEM',
  FETCH_USER = '[User] FETCH_USER',
  LOAD_USER_ITEM = '[User] LOAD_USER_ITEM',
  HANDLE_USER_ITEM_UPDATE = '[User] HANDLE_USER_ITEM_UPDATE',
  UPDATE_USER_ITEM = '[User] UPDATE_USER_ITEM',
  DISABLE_USER_ITEM = '[User] DISABLE_USER_ITEM',
  DELETE_USER_ITEM = '[User] DELETE_USER_ITEM',

  SEARCH_LIST = '[User] SEARCH_LIST',
  UPDATE_RESULTS = '[User] UPDATE_RESULTS',

  SET_SELECTED_DATE = '[User] SET_SELECTED_DATE',

  FETCH_USER_DAILY = '[User] FETCH_USER_DAILY',
  LOAD_USER_DAILY = '[User] LOAD_USER_DAILY',
  UPDATE_USER_DAILY = '[User] UPDATE_USER_DAILY',
  DISABLE_USER_DAILY = '[User] DISABLE_USER_DAILY',
  DELETE_USER_DAILY = '[User] DELETE_USER_DAILY',
}

/**
 * Every action in comprised of at least a type and an optinal
 * payload. Expressing actions as classes enables powerful type
 * checking in reducer functions.
 */
export const fetchUser = createAction(
  ActionTypes.FETCH_USER,
  props<{userId: string}>()
);

export const loadUser = createAction(
  ActionTypes.LOAD_USER_ITEM,
  props<{user: any}>()
);

export const addUserItem = createAction(
  ActionTypes.ADD_USER_ITEM,
  props<{user: any}>()
);

export const loadUserItem = createAction(
  ActionTypes.LOAD_USER_ITEM,
  props<{user: any}>()
);

export const handleUserItemUpdate = createAction(
  ActionTypes.HANDLE_USER_ITEM_UPDATE,
  props<{user: any}>()
);

export const updateUserItem = createAction(
  ActionTypes.UPDATE_USER_ITEM,
  props<{user: any}>()
);

export const disableUserItem = createAction(
  ActionTypes.DISABLE_USER_ITEM,
  props<{user: any}>()
);

export const deleteUserItem = createAction(
  ActionTypes.DELETE_USER_ITEM,
  props<{user: any}>()
);

export const searchList = createAction(
  ActionTypes.SEARCH_LIST,
  props<{query: any}>()
);

export const updateResults = createAction(
  ActionTypes.UPDATE_RESULTS,
  props<{users: any}>()
);



export const setSelectedDate = createAction(
  ActionTypes.SET_SELECTED_DATE,
  props<{selectedDateId: string}>()
);

export const fetchUserWeekly = createAction(
  ActionTypes.FETCH_USER_DAILY,
  props<{user: any}>()
);

export const loadUserWeekly = createAction(
  ActionTypes.LOAD_USER_DAILY,
  props<{userDaily: any}>()
);

export const updateUserWeekly = createAction(
  ActionTypes.UPDATE_USER_DAILY,
  props<{userDaily: any}>()
);

export const deleteUserWeekly = createAction(
  ActionTypes.DELETE_USER_DAILY,
  props<{dateId: any}>()
);