import { Component, ViewChild, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-weekly',
  // templateUrl: 'user-weekly.component.html',
  styleUrls: ['user-weekly.component.scss'],
  template: `
    <div #resizedDiv>
      <ngx-charts-pie-grid
        [view]="[previousWidthOfResizedDiv, 250]"
        [scheme]="colorScheme"
        [results]="calories"
        [minWidth]="0"
        [label]="'סה״כ'"
        [designatedTotal]="2000"
        (select)="onSelect($event)"
        style="display:inline-block">
      </ngx-charts-pie-grid>
      <ngx-charts-pie-grid
        [view]="[previousWidthOfResizedDiv, 250]"
        [scheme]="colorScheme"
        [results]="protein"
        [minWidth]="0"
        [label]="'סה״כ'"
        [designatedTotal]="2000"
        (select)="onSelect($event)"
        style="display:inline-block">
      </ngx-charts-pie-grid>
      <ngx-charts-pie-grid
        [view]="[previousWidthOfResizedDiv, 250]"
        [scheme]="colorScheme"
        [results]="carbs"
        [minWidth]="0"
        [label]="'סה״כ'"
        [designatedTotal]="2000"
        (select)="onSelect($event)"
        style="display:inline-block">
      </ngx-charts-pie-grid>
      <ngx-charts-pie-grid
        [view]="[previousWidthOfResizedDiv, 250]"
        [scheme]="colorScheme"
        [results]="fats"
        [minWidth]="0"
        [label]="'סה״כ'"
        [designatedTotal]="2000"
        (select)="onSelect($event)"
        style="display:inline-block">
      </ngx-charts-pie-grid>
    </div>
  `
})
export class AppUserWeekly {
  // view: any[] = [800, 400];
  calories: any[] = [
    {
      "name": this.translateService.instant('Calories'),
      "value": 1221
    }
  ];
  protein: any[] = [
    {
      "name": this.translateService.instant('Protein'),
      "value": 1221
    }
  ];
  carbs: any[] = [
    {
      "name": this.translateService.instant('Carbs'),
      "value": 1221
    }
  ];
  fats: any[] = [
    {
      "name": this.translateService.instant('Fats'),
      "value": 1221
    }
  ];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  autoScale: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  @ViewChild('resizedDiv') resizedDiv: ElementRef;
  public previousWidthOfResizedDiv: number = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService
  ) {
    console.log('AppUserWeekly');
  }

  ngAfterViewChecked() {
    if (this.previousWidthOfResizedDiv != this.resizedDiv.nativeElement.clientWidth/4) {
      //render your data for the chart using spread operator 
      // this.single = [...this.single]
    }
    this.previousWidthOfResizedDiv = this.resizedDiv.nativeElement.clientWidth/4;
  }

  onSelect(event) {
    console.log(event);
  }

}
