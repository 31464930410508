import * as fromActions from './core.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  isAdmin: boolean;
}

const initialState: State = {
  isAdmin: false
};

export const rootReducer = createReducer(
  initialState
)
