import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user';
import { UserDailyEffects } from './user-daily';
import { IngredientsEffects } from './ingredients';
import { DishesEffects } from './dishes';
import { CoreEffects } from './core/core.effects';

export const AppEffectsModules = EffectsModule.forRoot([
  CoreEffects,
  UserEffects,
  UserDailyEffects,
  IngredientsEffects,
  DishesEffects
]);
