import { Component, ViewChild, ElementRef, Input, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'user-daily-calories',
  // templateUrl: 'user-weekly.component.html',
  styleUrls: ['user-daily-calories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: /*html*/`
    <div #resizedDiv>
      <canvas #doughnutCanvas></canvas>
    </div>
  `
})
export class UserDailyCalories {
  // view: any[] = [800, 400];
  single: any[] = [
    {
      "name": "Calories",
      "value": 8940000
    },
    {
      "name": "Protein",
      "value": 5000000
    },
    {
      "name": "Carbs",
      "value": 7200000
    },
    {
      "name": "Fats",
      "value": 6200000
    }
  ];
  single2: any[] = [
    {
      "name": "Germany",
      "value": 8940000
    }
  ];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  totalConsumption: any = 0;

  @Input() color: string;
  @Input() type: string;
  @Input() datasets: any = [];
  @Input() dailyGoal: any;
  @Input() hideDailyGoalLabels: boolean = false;

  @ViewChild('resizedDiv') resizedDiv: ElementRef;
  public previousWidthOfResizedDiv: number = 0;

  @ViewChild("doughnutCanvas") doughnutCanvas: ElementRef;

  private doughnutChart: Chart;

  data: {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)"
        ],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF6384", "#36A2EB", "#FFCE56"]
      }
    ]
  }

  // doughnutChartLabels: Label[] = ['PUBG', 'Call of Duty', 'Fortnite'];
  // doughnutChartData: MultiDataSet = [
  //   [53, 30, 17]
  // ];
  // doughnutChartType: ChartType = 'doughnut';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService

  ) {
    console.log('AppUserWeekly');
  }

  ngAfterViewChecked() {
    // console.log('sdsdsdsdsdsdsdsd');
    // if (this.previousWidthOfResizedDiv != this.resizedDiv.nativeElement.clientWidth) {
    //   //render your data for the chart using spread operator 
    //   this.single = [...this.single]
    // }
    // this.previousWidthOfResizedDiv = this.resizedDiv.nativeElement.clientWidth;
    // this.render();
  }
  
  ngAfterViewInit() {
  
  }

  render(){
    if(!this.doughnutCanvas) return
      // setTimeout(()=>{
        // this.render();
      // },700)
    // };

    // this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
    //   type: "doughnut",
    //   // data: {
    //   //   labels: ["Red"], //"Blue", "Yellow", "Green", "Purple", "Orange"],
    //   //   datasets: [
    //   //     {
    //   //       label: "# of Votes",
    //         // data: [12, 3],
    //   //       backgroundColor: [
    //   //         "rgba(255, 99, 132, 0.2)",
    //   //         // "rgba(54, 162, 235, 0.2)",
    //   //         // "rgba(255, 206, 86, 0.2)",
    //   //         // "rgba(75, 192, 192, 0.2)",
    //   //         // "rgba(153, 102, 255, 0.2)",
    //   //         // "rgba(255, 159, 64, 0.2)"
    //   //       ],
    //   //       // hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF6384", "#36A2EB", "#FFCE56"]
    //   //     }
    //   //   ]
    //   // }
    // });

    const red_min_hex = '45';
    const red_min_dec = parseInt(red_min_hex, 16);
    const red_max_hex = 'cc';
    const red_max_dec = parseInt(red_max_hex, 16);
    const green_min_hex = '35';
    const green_min_dec = parseInt(green_min_hex, 16);
    const green_max_hex = 'ac';
    const green_max_dec = parseInt(green_max_hex, 16);
    const blue_min_hex = '20';
    const blue_min_dec = parseInt(blue_min_hex, 16);
    const blue_max_hex = '78';
    const blue_max_dec = parseInt(blue_max_hex, 16);

    const pi = Math.PI;

    const animateArc = (chart, baseColor) => {
      let arc = chart.getDatasetMeta(0).data[0];
      let angle = arc._view.endAngle + pi / 2;
      let angle_inverse = 2 * pi - angle;
      let blue: any = Math.round(
        (angle / (2 * pi)) * blue_max_dec + (angle_inverse / (2 * pi)) * blue_min_dec
      ).toString(16);
      if (arc._view.endAngle < pi / 2) {
        let green = Math.round(
          (angle / pi) * green_max_dec + ((pi - angle) / pi) * green_min_dec
        ).toString(16);
        if (green.length < 2) green = '0' + green;
        let color = baseColor; //`#${red_max_hex}${green}${blue}`;
        arc.round.backgroundColor = color;
        drawArc(chart, arc, color);
      } else {
        let red: any = Math.round(
          ((2 * pi - angle) / pi) * red_max_dec + ((angle - pi) / pi) * red_min_dec
        ).toString(16);
        if (red.length < 2) red = '0' + red;
        if (red === '45') red = 50;
        if (blue === '78') blue = 74;
        let color = `#${red}${green_max_hex}${blue}`;
        arc.round.backgroundColor = baseColor; // color;
        drawArc(chart, arc, baseColor);
      }
    }

    const drawArc = (chartm, arc, color) => {
      chart.canvas.parentNode.style.height = document.documentElement.clientWidth < 450  ? '128px' : '148px'; //'128px';
      chart.canvas.parentNode.style.width = document.documentElement.clientWidth < 450 ? '80px' : '128px';
      let x = (chart.chartArea.left + chart.chartArea.right) / 2;
      let y = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      chart.ctx.fillStyle = color;
      chart.ctx.strokeStyle = color;
      chart.ctx.beginPath();
      if (arc != null) {
        chart.ctx.arc(x, y, chart.outerRadius, arc._view.startAngle, arc._view.endAngle);
        chart.ctx.arc(x, y, chart.innerRadius, arc._view.endAngle, arc._view.startAngle, true);
      } else {
        chart.ctx.arc(x, y, chart.outerRadius, 0, 2 * pi);
        chart.ctx.arc(x, y, chart.innerRadius, 0, 2 * pi, true);
      }
      chart.ctx.fill();
    }

    const addCenterTextAfterUpdate = chart => {
      if (
        chart.config.options.elements.center &&
        chart.config.options.elements.centerSub &&
        chart.ctx
      ) {
        const centerConfig = chart.config.options.elements.center;
        const centerConfigSub = chart.config.options.elements.centerSub;
        const centerConfigTop = chart.config.options.elements.centerTop;
        const centerConfigTopLeft = chart.config.options.elements.centerTopLeft;
        const globalConfig = Chart.defaults.global;
        let fontStyle = centerConfig.fontStyle;
        let fontFamily = Chart.helpers.getValueOrDefault(centerConfig.fontFamily, 'Roboto');
        let fontSize = Chart.helpers.getValueOrDefault(centerConfig.minFontSize, 10);
        let maxFontSize = Chart.helpers.getValueOrDefault(centerConfig.maxFontSize, 90);
        let maxText = Chart.helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);
        do {
          chart.ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
          let textWidth = chart.ctx.measureText(maxText).width;
          if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) fontSize += 1;
          else {
            fontSize -= 1;
            break;
          }
        } while (true);
        chart.center = {
          font: Chart.helpers.fontString(fontSize, fontStyle, fontFamily),
          fillStyle: Chart.helpers.getValueOrDefault(
            centerConfig.fontColor,
            globalConfig.defaultFontColor
          ),
        };
        fontSize = Chart.helpers.getValueOrDefault(centerConfigSub.minFontSize, 5);
        const fontSizeTopLeft = Chart.helpers.getValueOrDefault(centerConfigTopLeft.minFontSize, 10);
        maxFontSize = Chart.helpers.getValueOrDefault(centerConfigSub.maxFontSize, 25);
        maxText = centerConfigSub.text;
        do {
          chart.ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
          let textWidth = chart.ctx.measureText(maxText).width;
          if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) fontSize += 1;
          else {
            fontSize -= 1;
            break;
          }
        } while (true);
        chart.centerSub = {
          font: Chart.helpers.fontString(fontSize, fontStyle, fontFamily),
          fillStyle: Chart.helpers.getValueOrDefault(
            centerConfigSub.fontColor,
            globalConfig.defaultFontColor
          ),
        };
        chart.centerTop = {
          font: Chart.helpers.fontString(fontSize, fontStyle, fontFamily),
          fillStyle: Chart.helpers.getValueOrDefault(
            centerConfigTop.fontColor,
            globalConfig.defaultFontColor
          ),
        };
        chart.centerTopLeft = {
          font: Chart.helpers.fontString(fontSizeTopLeft, fontStyle, fontFamily),
          fillStyle: Chart.helpers.getValueOrDefault(
            centerConfigTop.fontColor,
            globalConfig.defaultFontColor
          ),
        };
      }
    }

    const roundCornersAfterUpdate = chart => {
      if (chart.config.options.elements.arc.roundCorners !== undefined) {
        let arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundCorners];
        arc.round = {
          x: (chart.chartArea.left + chart.chartArea.right) / 2,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          radius: (chart.outerRadius + chart.innerRadius) / 2,
          thickness: (chart.outerRadius - chart.innerRadius) / 2,
          backgroundColor: arc._model.backgroundColor,
        };
      }
    };

    const addCenterTextAfterDraw = chart => {
      if (chart.center && chart.centerSub  && chart.centerTop) {
        chart.ctx.textAlign = 'center';
        chart.ctx.textBaseline = 'middle';
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        const lowerY = (chart.chartArea.top + chart.chartArea.bottom) / 2 + (document.documentElement.clientWidth < 450 ? 20 : 30); //65;
        const upperY = (chart.chartArea.top + chart.chartArea.bottom) / 2 - (document.documentElement.clientWidth < 450 ? 18 : 25); //65;
        const centerXLeft = (chart.chartArea.left + chart.chartArea.right) / 2 - 15;
        const centerYSmall = (chart.chartArea.top + chart.chartArea.bottom) / 2 - 10;
        const centerConfig = chart.config.options.elements.center;
        chart.ctx.font = chart.center.font;
        chart.ctx.fillStyle = chart.center.fillStyle;
        chart.ctx.fillText(centerConfig.text, centerX, centerY);
        const centerSubConfig = chart.config.options.elements.centerSub;
        chart.ctx.font = chart.centerSub.font;
        chart.ctx.fillStyle = chart.centerSub.fillStyle;
        chart.ctx.fillText(centerSubConfig.text, centerX, lowerY);
        const centerTopConfig = chart.config.options.elements.centerTop;
        chart.ctx.font = chart.centerTop.font;
        chart.ctx.fillStyle = chart.centerTop.fillStyle;
        chart.ctx.fillText(centerTopConfig.text, centerX, upperY);
        const centerTopLeftConfig = chart.config.options.elements.centerTopLeft;
        chart.ctx.font = chart.centerTopLeft.font;
        chart.ctx.fillStyle = chart.centerTop.fillStyle;
        chart.ctx.fillText(centerTopLeftConfig.text, centerXLeft, upperY);
      }
    };

    const roundCornersAfterDraw = chart => {
      if (chart.config.options.elements.arc.roundCorners !== undefined) {
        var arc = chart.getDatasetMeta(0).data[chart.config.options.elements.arc.roundCorners];
        var startAngle = pi / 2 - arc._view.startAngle;
        var endAngle = pi / 2 - arc._view.endAngle;
        chart.ctx.save();
        chart.ctx.translate(arc.round.x, arc.round.y);
        chart.ctx.fillStyle = arc.round.backgroundColor;
        chart.ctx.beginPath();
        chart.ctx.arc(
          arc.round.radius * Math.sin(startAngle),
          arc.round.radius * Math.cos(startAngle),
          arc.round.thickness,
          0,
          2 * pi
        );
        chart.ctx.arc(
          arc.round.radius * Math.sin(endAngle),
          arc.round.radius * Math.cos(endAngle),
          arc.round.thickness,
          0,
          2 * pi
        );
        chart.ctx.fill();
        chart.ctx.restore();
      }
    };

    let precentage = Math.round((this.datasets[0] * 100 / this.dailyGoal));
    precentage = this.dailyGoal ? precentage : this.translateService.instant('NOT_DEFINED');
    const isOverGoal = this.datasets[1] < 0;
    const arcColor = isOverGoal ? this.color : '#D6EBEC';
    const progressColor = isOverGoal ? 'red' : this.color;
    this.totalConsumption = this.datasets[0];
    let progress = this.datasets[0];
    let progressLeft = this.datasets[1];
    if(isOverGoal) {
      const overConsumption = -1 * this.datasets[1] % parseInt(this.dailyGoal);
      // progress
      progress = overConsumption
      // this.datasets[0] = overConsumption;
      // progress left
      progressLeft = this.dailyGoal - overConsumption
      // this.datasets[1] = this.dailyGoal - overConsumption;
    }
    // console.log('isOverGoal, arcColor', isOverGoal, precentage);
    // console.log('isOverGoal, arcColor', isOverGoal, arcColor);
    var datasets = [{
      "data": [
        progress || 0,
        progressLeft || 0,
        // 84, 16
      ],
      "backgroundColor": [ 
        this.color
      ]
    }];
    var chartData = {
      type: 'doughnut',
      data: { 
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        segmentShowStroke: false,
        events: [],
        title: {
          display: true,
          text: [this.translateService.instant(this.type)],
          fontSize: document.documentElement.clientWidth < 400 ? 15 : 13,
          fontColor: this.color,
          padding: 0,
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 20
          }
        },
        elements: {
          arc: {
            roundCorners: 0,
            borderWidth: 0
          },
          // layout: {
          //   padding: {
          //       left: 0,
          //       right: 0,
          //       top: 100,
          //       bottom: 0
          //   }
          // },
          center: {
            maxText: "100%",
            text: this.hideDailyGoalLabels ? `${this.type === 'Calories' ? '' : 'g ' }${this.totalConsumption}` : 
              `מ ${this.type === 'Calories' ? '' : 'g' }${this.dailyGoal}`,
            // text: ['Sensor Chart', 'sdds', ' sd sds d'],
            fontColor: this.hideDailyGoalLabels ? this.color : "#646464",
            fontFamily: "Roboto",
            fontStyle: "normal",
            minFontSize: this.hideDailyGoalLabels ? 10 : 5,
            maxFontSize: this.hideDailyGoalLabels ? 20 :
              document.documentElement.clientWidth < 450 ? 13 : 13 //50 // 90
          },
          centerLeft: {
            maxText: "100%",
            text: `${this.translateService.instant('Calories')}מ g${this.dailyGoal}`,
            fontColor: "#646464",
            fontFamily: "Roboto",
            fontStyle: "normal",
            minFontSize: 13,
            maxFontSize: 13 //50 // 90
          },
          centerRight: {
            maxText: "100%",
            text: this.translateService.instant('Calories'),
            fontColor: "#646464",
            fontFamily: "Roboto",
            fontStyle: "normal",
            minFontSize: 15,
            maxFontSize: 15 //50 // 90
          },
          centerSub: {
            text: this.hideDailyGoalLabels ? '' : `${ precentage }${ this.dailyGoal ? '%' : ''}`,
            fontColor: this.color,//"#a6a6a6",
            minFontSize: 15,
            maxFontSize: document.documentElement.clientWidth < 450 ? 15 : 20 // 25
          },
          centerTop: {
            text: this.hideDailyGoalLabels ? '' : `${this.type === 'Calories' ? '' : 'g ' }${this.totalConsumption}`,
            fontColor: this.color,//"#a6a6a6",
            minFontSize: 10,
            maxFontSize: 20
          },
          centerTopLeft: {
            text: ``,
            fontColor: "#a6a6a6",
            minFontSize: 12,
            maxFontSize: 12
          }
        },
        animation: {
          onProgress: animation => {
            animation.easing = 'linear';
            animateArc(animation.chart, progressColor)
          }
        }
      }
    }

    var ctx = this.doughnutCanvas.nativeElement.getContext('2d');
    var chart = new Chart(ctx, {
      ...chartData,
      plugins: [{
        beforeDraw: chart => {
          drawArc(chart, null, arcColor);
        },
        afterUpdate: chart => {
          addCenterTextAfterUpdate(chart);
          roundCornersAfterUpdate(chart);
        },
        afterDraw: chart => {
          addCenterTextAfterDraw(chart);
          roundCornersAfterDraw(chart);
        },
        resize: () => new Chart(ctx, {
          ...chartData,
          plugins: [{
            beforeDraw: chart => {
              
              drawArc(chart, null, arcColor);
            },
            afterUpdate: chart => {
              addCenterTextAfterUpdate(chart);
              roundCornersAfterUpdate(chart);
            },
            afterDraw: chart => {
              addCenterTextAfterDraw(chart);
              roundCornersAfterDraw(chart);
            },
          }]
        })
      }]
    });
    
  }

  ngOnChanges(propsChanged: SimpleChanges) {
    { 
      console.log('propsChangedpropsChanged', propsChanged);
      if(propsChanged.datasets && propsChanged.datasets.currentValue){
        setTimeout(()=>{
          this.render();
        },0)
      }
      if(propsChanged.dailyGoal && propsChanged.dailyGoal.currentValue ){
        setTimeout(()=>{
          this.render();
        },0)
    }
    }
  } 

  onSelect(event) {
    console.log(event);
  }

}
