import {Injectable} from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export enum ToastMessages {
  USER_INFO_UPDATED_SUCCESSFULLY = 'USER_INFO_UPDATED_SUCCESSFULLY',
  USER_INFO_UPDATED_FAILURE = 'USER_INFO_UPDATED_FAILURE',
  USER_DISH_ADDED = 'USER_DISH_ADDED',
  USER_DISH_UPDATED = 'USER_DISH_UPDATED',
  USER_DISH_REMOVED = 'USER_DISH_REMOVED',
  USER_DISH_STATUS_UPDATED = 'USER_DISH_STATUS_UPDATED',
}

@Injectable()
export class ToastService {

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  async presentToast(toastMessages: ToastMessages, header?: string, color?: string) {
    const toast = await this.toastController.create({
      header: header ? this.translateService.instant(header) : '',
      message: this.translateService.instant(toastMessages),
      cssClass: 'toast-message',
      duration: 3000,
      color: color ? color : toastMessages === ToastMessages.USER_INFO_UPDATED_FAILURE ? 'danger' : 'medium'
    });
    toast.present();
  }

  async presentToastWithOptions(message :string) {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }
}
