import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { Observable } from 'rxjs';
import { 
  fetchIngredientsList, 
  addIngredientItem,
  updateIngredientItem,
  disableIngredientItem,
  deleteIngredientItem,
  handleIngredientItemUpdate
} from '~core/store/ingredients/ingredients.actions';
import { 
  getIngredients,
  getIngredientsState
} from '~core/store/ingredients/ingredients.selectors';

@Injectable()
export class IngredientsApi {

  ingredientsState$: Observable<any> = this.store.pipe(select(getIngredientsState));
  ingredients$: Observable<any> = this.store.pipe(select(getIngredients));

  constructor(
    private store: Store<AppState>
  ) { }

  onFetchIngredients(_options?: any) {
    this.store.dispatch(fetchIngredientsList());
  }

  onCreateIngredient(ingredient: any) {
    this.store.dispatch(addIngredientItem(ingredient));
  }

  onUpdateIngredient(ingredient: any) {
    this.store.dispatch(handleIngredientItemUpdate(ingredient));
  }

  onDisableIngredient(ingredient: any) {
    this.store.dispatch(disableIngredientItem(ingredient));
  }

  onDeleteIngredient(ingredient: any) {
    this.store.dispatch(deleteIngredientItem(ingredient));
  }

}
