import { AuthApi } from './auth.api';
import { UserApi } from './user.api';
import { IngredientsApi } from './ingredients.api';
import { DishesApi } from './dishes.api';
import { UserDailyApi } from './user-daily.api';

// Thers APIs are for communicating with the store
// all store dispatches should be called using these APIs

export const APP_APIS = [
    AuthApi,
    UserApi,
    UserDailyApi,
    IngredientsApi,
    DishesApi
]