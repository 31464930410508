import { Component, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';

export enum SizeType {
	GRAM = 'gram',
	ML = 'ml',
}

export interface SizeData {
	name: string;
	anmount: number,
  type: SizeType,
  isDefault: boolean
}

export enum NutritionType {
	PROTEIN = 'protein',
	CARBS = 'carbs',
	FATS ='fats'
}

@Component({
  selector: 'app-ingredient-size',
  styleUrls: ['ingredient-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <form [formGroup]="formGroup2" (change)="onChange()">
        <ion-row style="width:100%">
          <ion-col>
            <input formControlName="name" autofocus placeholder="{{ 'size-name' | translate }}" type="text" value="{{ sizeType }}"/>
          </ion-col>
          <ion-col>
            <ion-select disabled color="light" [value]="sizeType" class="portion-size-select" okText="Okay" icon="add" placeholder="{{ 'Portion Size' | translate }}" cancelText="Dismiss" interface="popover">
              <ion-select-option value="100 Gram">{{SizeType.GRAM | translate}}</ion-select-option>
              <ion-select-option [value]="SizeType.ML">{{SizeType.ML | translate}}</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col>
            <input formControlName="amount" autofocus placeholder="{{ 'size-amount' | translate }}" type="number" />
          </ion-col>
        </ion-row>

        <!-- <hr style="background-color: lightgray;"/> -->
      </form>
    </div>
  `
})
export class IngredientSizeComponent {
  SizeType = SizeType;
  sizeForm: FormGroup;
  @Input() sizeType: string;
  @Input() formGroup2: FormGroup;
  @Input() sizeData: SizeData;
  // @Input() set formGroup(_sizeData){
  //   this.sizeForm = new FormGroup({
  //     name: new FormControl(''),
  //     amount: new FormControl(''),
  //     type: new FormControl('')
  //   }
  // }

  @Input() onReset: Function;
  @Input() onRemove: Function;

  @ViewChild('myForm') myForm;

  constructor(public popoverController: PopoverController) {}

  onDishReset(){
    this.onReset();
    this.close()
  }

  onDishRemove(){
    this.onRemove();
    this.close()
  }

  onChange(item){

    // this.formGroup2.controls.name.setValue('asdasds');
    // debugger
  }

  close() {
    this.popoverController.dismiss();
  }
}
