import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteOptions, AutoCompleteComponent } from 'ionic4-auto-complete';
import { CompleteTestService } from '../../../providers/autocomplete.provider';
import { DateTime } from 'luxon';
import { ModalController } from '@ionic/angular';
import { IngredientAmountSelectionModal } from 'src/app/modals/ingredient-amount-selection/ingredient-amount-selection.page';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddNewIngredientPage } from 'src/app/modals/add-new-ingredient/add-new-ingredient.page';
import { AddNewDishNameModal } from 'src/app/modals/new-dish-add-name/new-dish-add-name.page';
import { animate, style, transition, trigger } from '@angular/animations';

export enum NutritionType {
	PROTEIN = 'protein',
	CARBS = 'carbs',
	FATS ='fats'
  }

@Component({
  selector: 'weekly-plan',
  // templateUrl: 'user-daily-status.component.html',
  styleUrls: ['weekly-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div #resizedDiv>
	<ion-row class="goal-meters-container ion-no-margin ion-padding-bottom">
    <ion-col size="12" sizeMd="8" sizeLg="6" style="margin:auto">
      <ion-row class="goal-meters-bar ion-no-margin ion-margin-bottom" >
        <ion-col size="3" sizeMd="3" sizeLg="3" style="margin:auto">
          <user-daily-calories 
            [datasets]="datasets['calories']" 
            [dailyGoal]="dailyGoal['calories']" 
            style="margin:auto" type="Calories" color="#913BB5"
          >
					</user-daily-calories>
				</ion-col>
				<ion-col size="3" sizeMd="3" sizeLg="3" style="margin:auto">
					<user-daily-calories [datasets]="datasets['protein']" [dailyGoal]="dailyGoal['protein']" style="margin:auto" type="Protein" color="#69C370">
					</user-daily-calories>
				</ion-col>
				<ion-col size="3" sizeMd="3" sizeLg="3" style="margin:auto">
					<user-daily-calories [datasets]="datasets['carbs']" [dailyGoal]="dailyGoal['carbs']" style="margin:auto" type="Carbs" color="#01ADC1">
					</user-daily-calories>
				</ion-col>
				<ion-col size="3" sizeMd="3" sizeLg="3" style="margin:auto">
					<!-- <ion-fab vertical="bottom" horizontal="start" style="margin-bottom: -35px;margin-right: -32%;box-shadow: none;">
						<ion-fab-button color="light">
							<ion-icon name="settings-outline"></ion-icon>
						</ion-fab-button>
					  </ion-fab> -->
					<user-daily-calories [datasets]="datasets['fats']" [dailyGoal]="dailyGoal['fats']" style="margin:auto" type="Fats" color="#EC9C9A">
					</user-daily-calories>
				</ion-col>
			</ion-row>
		</ion-col>
		<!-- <ion-col size="12" sizeMd="8" sizeLg="6" style="margin:auto">
			<app-user-weekly>
			</app-user-weekly>
		</ion-col> -->

  </ion-row>
  <ion-row class="ion-padding-top">
  <ion-col size="12" sizeMd="8" sizeLg="6" class="ion-text-center" color="medium" style="margin: auto">

    <div class="ion-margin-start ion-margin-end">
    <ng-template #empty let-attrs="attrs">
      <ion-item class="dishes-suggestions-empty" lines="none">
        <ion-label>{{ 'Sorry, no dishes found' | translate }}</ion-label>
        <div class="dishes-suggestions-empty-icon"></div>
      </ion-item>

    </ng-template>

    <ng-template #suggestions let-attrs="attrs" style="background-color: none">
      <ion-item class="ion-no-margin ion-no-padding" size="small" lines="none">
        <ion-thumbnail [slot]="'start'" class="flag">
          <img src="{{ attrs.data?.image?.imageUrl }}" onerror="this.onerror=null;this.src='./assets/images/default_dish.svg'"/>
        </ion-thumbnail>
        <ion-label >
            <span [innerHtml]="attrs.data?.name | boldprefix:attrs.keyword"></span>
        </ion-label>
        <ion-chip slot="end" color="{{ attrs.data?.isIngredientAsDish ? 'tertiary' : attrs.data?.isMyDish ? 'secondary' : 'success' }}">
          <ion-label>{{ attrs.data?.isIngredientAsDish ? 'רכיב' : attrs.data?.isMyDish ? 'מנה שלי' : 'מנה' }}</ion-label>
        </ion-chip>
      </ion-item>
    </ng-template>

    <ion-auto-complete #searchbar [(model)]="selected" class="ion-text-right" style="width:100%"
      [multi]="false"
      [dataProvider]="provider"
      [disabled]="false"
      [emptyTemplate]="empty"
      [template]="suggestions"
      [options]="options"
      [maxResults]="10"
      [styles]="styles"
      [autoFocusSuggestion]="false"
      [removeButtonIcon]="'assets/icons/clear.svg'"
      [removeButtonColor]="'dark'"
      (itemSelected)="onSelect('itemSelected', $event)"
      (focus)="on('focus', $event)"
      >
    </ion-auto-complete>
    </div>
  </ion-col>
</ion-row>

	<ion-row class="items ion-no-padding">
    <ion-col class="ion-no-padding" size="12" sizeMd="8" sizeLg="6" style="margin:auto" color="medium">
    
      <ion-list-header>
        {{ 'Day' | translate }} {{ dayOfWeek | translate }} | {{ 'weekly plan' | translate }}
      </ion-list-header>

        <ion-card *ngFor="let dailyDish of userDaily; let index = index" class="dish-card" [disabled]="dailyDish.consumed">

          <ion-card-content class="dish-item ion-no-padding">

            <ion-row>

              <ion-col size="3" class="dish-item-image-container">
                <img src="{{dailyDish.dish?.image?.imageUrl}}" onerror="this.onerror=null;this.src='./assets/images/default_dish.svg'"/>
              </ion-col>
              
              <ion-col size="8" class="ion-text-right">
                <ion-item class="dish-item-content" lines="none">
                  <ion-label>

                    <!-- DISH-NAME-AND-DESCRIPTION -->
                    <h2 class="ion-no-margin" style="margin:0">{{ dailyDish.dish.name }}</h2>
                    <!-- <h3 class="ion-no-margin" style="margin:0">{{ dailyDish.dish?.shortDescription }}</h3> -->
                    <h3 class="dish-sub-title ion-no-margin" style="margin:0">{{ dailyDish.dish?.isIngredientAsDish ? showAmoutWithSizeType(dailyDish.dish.ingredients[0]) : dailyDish.dish.shortDescription }}</h3>

                    <table>
                      <!-- <tr style="font-size: 15px">
                        <th class="dish-value-count-label-container" style="color: #913BB5;padding-left: 10px">{{ getCalorieValue(dailyDish.dish.ingredients) }}</th>
                        <th class="dish-value-count-label-container" style="color: #69C370;padding-left: 5px">{{ getTotalBy(dailyDish.dish.ingredients, 'protein') }}</th>
                        <th class="dish-value-count-label-container" style="color: #01ADC1;padding-left: 5px">{{ getTotalBy(dailyDish.dish.ingredients, 'carbs') }}</th>
                        <th class="dish-value-count-label-container" style="color: #EC9C9A;padding-left: 5px">{{ getTotalBy(dailyDish.dish.ingredients, 'fats') }}</th>
                      </tr>
                      <tr style="font-size: 12px;zoom:0.8">
                        <td class="dish-value-count-label-container" style="color: #913BB5; padding-left: 5px">
                          <ion-text>{{ 'Calories' | translate }}</ion-text>
                        </td>
                        <td class="dish-value-count-label-container" style="color: #69C370; padding-left: 5px">
                          <ion-text>{{ getPrecentage(dailyGoal['protein'], getTotalBy(dailyDish.dish.ingredients, 'protein')) }} {{ 'Protein' | translate }}</ion-text>
                        </td>
                        <td class="dish-value-count-label-container" style="color: #01ADC1; padding-left: 5px">
                          <ion-text>{{ getPrecentage(dailyGoal['carbs'], getTotalBy(dailyDish.dish.ingredients, 'carbs')) }} {{ 'Carbs' | translate }}</ion-text>
                        </td>
                        <td class="dish-value-count-label-container" style="color: #EC9C9A; padding-left: 5px">
                          <ion-text size="small">{{ getPrecentage(dailyGoal['fats'], getTotalBy(dailyDish.dish.ingredients, 'fats')) }} {{ 'Fats' | translate }}</ion-text>
                        </td>
                      </tr> -->
                      <tr style="font-size: 15px">
                        <th class="dish-value-count-label-container" style="color: #913BB5;padding-left: 10px">{{ getCalorieValue(dailyDish.dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dailyDish.dish.ingredients) }}</th>
                        <th class="dish-value-count-label-container" style="color: #69C370;padding-left: 5px">{{ getTotalBy(dailyDish.dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dailyDish.dish.ingredients, 'protein') | number : '1.0' }}</th>
                        <th class="dish-value-count-label-container" style="color: #01ADC1;padding-left: 5px">{{ getTotalBy(dailyDish.dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dailyDish.dish.ingredients, 'carbs') | number : '1.0' }}</th>
                        <th class="dish-value-count-label-container" style="color: #EC9C9A;padding-left: 5px">{{ getTotalBy(dailyDish.dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dailyDish.dish.ingredients, 'fats') | number : '1.0' }}</th>
                      </tr>
                      <tr style="font-size: 12px;zoom:0.8">
                        <td class="dish-value-count-label-container" style="color: #913BB5; padding-left: 5px">
                          <ion-text>{{ 'Calories' | translate }}</ion-text>
                        </td>
                        <td class="dish-value-count-label-container" style="color: #69C370; padding-left: 5px">
                          <ion-text>{{ getTotalPrecent(dailyDish.dish.ingredients, 'protein') | percent: '1.0' }} {{ 'Protein' | translate }}</ion-text>
                          <!-- <ion-text>{{ getTotalBy(dailyDish.dish.ingredients, 'protein') / getCalorieValue(dailyDish.dish.ingredients) }}</ion-text> -->
                          <!-- <ion-text>{{ getPrecentage(dailyGoal['protein'], getTotalBy(dailyDish.dish.ingredients, 'protein')) | percent: '1.0' }} {{ 'Protein' | translate }}</ion-text> -->
                        </td>
                        <td class="dish-value-count-label-container" style="color: #01ADC1; padding-left: 5px">
                          <ion-text>{{ getTotalPrecent(dailyDish.dish.ingredients, 'carbs') | percent: '1.0' }} {{ 'Carbs' | translate }}</ion-text>
                          <!-- <ion-text>{{ getPrecentage(dailyGoal['carbs'], getTotalBy(dailyDish.dish.ingredients, 'carbs')) | percent: '1.0' }} {{ 'Carbs' | translate }}</ion-text> -->
                        </td>
                        <td class="dish-value-count-label-container" style="color: #EC9C9A; padding-left: 5px">
                           <ion-text>{{ getTotalPrecent(dailyDish.dish.ingredients, 'fats') | percent: '1.0' }} {{ 'Fats' | translate }}</ion-text>
                          <!-- <ion-text size="small">{{ getPrecentage(dailyGoal['fats'], getTotalBy(dailyDish.dish.ingredients, 'fats')) | percent: '1.0' }} {{ 'Fats' | translate }}</ion-text> -->
                        </td>
                      </tr>
                    </table>

                  </ion-label>
                </ion-item>
              </ion-col>
              
              <ion-col size="1" class="dish-side-button ion-text-center">

                <div *ngIf="!dailyDish.consumed" class="dish-toggle-button-container" (click)="onItemRemove(dailyDish,$event,index)">
                  <ion-icon class="dish-toggle-button" color="dark" size="large" name="remove"></ion-icon>
                </div>

              </ion-col>
              
            </ion-row>

            <!-- DISH-MENU-TOGGLE-BUTTON -->
            <ion-row>
              <ion-col class="ion-no-padding">
                <ion-button class="button-open-menu ion-no-margin" expand="full" size="small" (click)="toggleMenu(dailyDish.dish, index);saveTempDish(dailyDish.dish, index);" [disabled]="dailyDish.dish.isHideMenu">
                  <ion-icon *ngIf="!dailyDish.dish.isIngredientAsPortion" name="{{ isOpenMenuArray[index]?.isOpenMenu ? 'chevron-up-outline' : 'chevron-down-outline' }}"></ion-icon>
                  <ion-icon *ngIf="dailyDish.dish.isHideMenu" name="lock-closed-outline"></ion-icon>              
                </ion-button>
              </ion-col>
            </ion-row>

            <!-- DISH-MENU-VIEW -->
            <ion-row class="menu-form ion-text-right" *ngIf="isOpenMenuArray[index]?.isOpenMenu && menuForm" [@inOutAnimation]>
            <ion-col>
            <ion-list style="">
                  <form [formGroup]="menuForm">
                    <app-menu-ingredient *ngFor="let ingredientFormGroup of menuForm.controls; let index = index" [ingredientSelectedSizeForm]="ingredientFormGroup" [ingredient]="dailyDish.dish.ingredients[index]">
                      <ion-button class="menu-btn2" size="small" color="danger" fill="clear" style="height:20px" (click)="onRemoveIngredientAlert(dailyDish.dish, index)" disabled="{{dailyDish.dish.ingredients?.length === 1}}">
                        <ion-icon name="remove-circle-outline" slot="icon-only"></ion-icon>
                      </ion-button>
                    </app-menu-ingredient>
                    <ion-button class="menu-btn2 ion-text-right ion-margin-bottom" size="small" [disabled]="dailyDish.dish.isHide" fill="clear" (click)="onOpenAddNewIngredientModal(dailyDish, index)">
                      {{ 'ADD_NEW_INGREDIENT' | translate }}
                      <ion-icon name="add-outline"></ion-icon>
                    </ion-button>
                    <br/>
                    <ion-row>
                      <ion-col>
                        <ion-button class="menu-btn" expand="block" [disabled]="dailyDish.dish.isHide" (click)="updateDishMenu(dailyDish, index)" [disabled]="!menuForm.valid">{{ 'SAVE_CHANGES' | translate }}</ion-button>
                      </ion-col>
                      <ion-col>
                        <ion-button class="menu-btn" expand="block" [disabled]="dailyDish.dish.isHide" (click)="saveAsNewDish(dailyDish, index)">
                          {{ 'SAVE_AS_NEW_DISH' | translate }}
                          <ion-icon name="add-outline"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-button class="cancel-btn" color="danger" expand="block" [disabled]="dailyDish.dish.isHide" (click)="resetMenuChanges(dailyDish.dish, index);toggleMenu(dailyDish.dish)">{{ 'CANCEL' | translate }}</ion-button>
                      </ion-col>
                    </ion-row>
                  </form>
                </ion-list>
                </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
		</ion-col>
	</ion-row>
  </div>
  `,
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ 'max-height': '0', opacity: 0 }),
            animate('0.6s ease-out', 
                    style({ 'max-height': '400px', opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ 'max-height': '400px', opacity: 1 }),
            animate('0.6s ease-in', 
                    style({ 'max-height': '0', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class WeeklyPlanComponent {
  // view: any[] = [800, 400];
  dailyGoal2: any[] = [
    {
      "name": this.translateService.instant('Calories'),
      "value": 1221
    }
  ];
  protein: any[] = [
    {
      "name": this.translateService.instant('Protein'),
      "value": 1221
    }
  ];
  carbs: any[] = [
    {
      "name": this.translateService.instant('Carbs'),
      "value": 1221
    }
  ];
  fats: any[] = [
    {
      "name": this.translateService.instant('Fats'),
      "value": 1221
    }
  ];

  styles = {
    'list': {'width': '94%', left: 'initial', fontSize: "10px", 'z-index': 10000, bottom: 'initial' }
  }

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  autoScale: boolean = true;
  
  public options: AutoCompleteOptions;
  public selected: any[] = [];

  public sortableOptions: any;

  public isOpenMenuArray: {} = {};
  public tempDishBeforeChanges: {} = {};
  public menuForm: FormArray;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  @ViewChild('searchbar') searchbar: AutoCompleteComponent;
  @ViewChild('resizedDiv') resizedDiv: ElementRef;
  public previousWidthOfResizedDiv: number = 0;

  datasets: any = {
    'calories': [0,0],
    'protein': [0,0],
    'carbs': [0,0],
    'fats':[0,0]
  };
  dailyConsumption = {
    totalCalories: 0,
    totalProtein: 0,
    totalCarbs: 0,
    totalFats: 0
  };
  @Input() userDaily: any;
  @Input() dayOfWeek: string;
  @Input() dailyGoal: any = {
    calories: 0,
    protein: 0,
    carbs: 0,
    fats: 0
  };
  @Input() ingredients: any = [];


  @Output() onAddDishToDaily: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDishStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDailyMenuReorderUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveDish: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddDishToMyDishes: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private translateService: TranslateService,
    public provider: CompleteTestService,
    private modalController: ModalController
  ) {
    console.log('AppUserWeekly', this.userDaily);
    this.options = new AutoCompleteOptions();

    this.options.autocomplete = 'on';
    // this.options.cancelButtonIcon = 'assets/icons/clear.svg';
    // this.options.clearIcon = 'assets/icons/clear.svg';
    this.options.debounce = 350;
    this.options.placeholder = this.translateService.instant('Type text to add a dish..');
    // this.options.searchIcon = 'assets/icons/add-user.svg';
    this.options.type = 'search';

    this.sortableOptions = {
      onUpdate: (event: any) => {
        const dailyGoal = this.userDaily;
        this.onDailyMenuReorder(dailyGoal,this.dayOfWeek);
      }
    };
  }

  ngAfterViewChecked() {
    if (this.previousWidthOfResizedDiv != this.resizedDiv.nativeElement.clientWidth/4) {
      //render your data for the chart using spread operator 
      // this.single = [...this.single]
    }
    this.previousWidthOfResizedDiv = this.resizedDiv.nativeElement.clientWidth/4;
  }

  async onSelect(type, selectedDish) {


    if(selectedDish.isIngredientAsDish){
      if(selectedDish.ingredients[0].size !== 'portion'){
        const modal = await this.modalController.create({
          component: IngredientAmountSelectionModal,
          cssClass: 'ingredient-amount-selected-modal',
          backdropDismiss: false,
          presentingElement: await this.modalController.getTop(),
          componentProps: {
            moreSizes: selectedDish.ingredients[0]['moreSizes']
          }
        });
        await modal.present();
        var { data } = await modal.onWillDismiss();
        if(data) {
          selectedDish.ingredients[0].selectedAmount = data.amountSelected;
          selectedDish.name = `${selectedDish.name}`; //(${data.amountSelected})`;
          selectedDish.ingredients[0].selectedSize = {
            size: data,
            calories: selectedDish.ingredients[0].calories * (data.amountSelected / 100),
            protein: selectedDish.ingredients[0].protein * (data.amountSelected / 100),
            carbs: selectedDish.ingredients[0].carbs * (data.amountSelected / 100),
            fats: selectedDish.ingredients[0].fats * (data.amountSelected / 100)
          }
          selectedDish.shortDescription = data.selectedType;
        }
        else {
          return;
        }
      }
      if(selectedDish.ingredients[0].size === 'portion'){
        data = {
          amountSelected: 'portion',
          selectedType: 'portion'
        }
        selectedDish.ingredients[0].selectedAmount = data.amountSelected;
        selectedDish.name = `${selectedDish.name}`;
        selectedDish.ingredients[0].selectedSize = {
          size: data,
          calories: selectedDish.ingredients[0].calories,
          protein: selectedDish.ingredients[0].protein,
          carbs: selectedDish.ingredients[0].carbs,
          fats: selectedDish.ingredients[0].fats
        };
        selectedDish.shortDescription = selectedDish.isIngredientAsDish ? data.selectedType : selectedDish.shortDescription;
      }
    }

    this.onAddDishToDaily.emit({ 
      dayOfWeek: this.dayOfWeek, 
      selectedDish
    });

    setTimeout(()=>{
      this.totalCaloriesOfDay();
    },0)
  }
  on(output, event):void {
    if (output === 'ionAutoInput') {
      var newIngredient = event;
    }

    console.log(output,'sdsd');
    // console.log(event);
      // this.searchbar.showResultsFirst = true;

      // this.searchbar.registerOnChange(()=>{
      //   console.log(output,'12121212121');
      // })
      // this.provider.getResults('').subscribe(()=>{
        this.searchbar.showItemList();
      // })
      // this.searchbar.showItemList();
  }


  onAddToList() {

  }

  // getTotalBy(value: [], type){
  //   return value.reduce((sum, ingredient)=>{
  //     const value: any = ingredient[type] < 1 ? Math.ceil(ingredient[type]) : Math.round(ingredient[type]);
  //     return sum + parseFloat(value || 0)
  //   }, 0);
  // }

  getTotalBy(value: [], type){
    if(!value) {
      console.log('ingredientingredient - value');
      return;
    }
    let total = value.reduce((sum, ingredient)=>{
      if(!ingredient){
        console.log('ingredientingredient - ingredient', sum);
        return sum;
      }
      // const value: any = ingredient[type] < 1 ? Math.ceil(ingredient[type]) : Math.round(ingredient[type]);
      const value: any = ingredient['selectedSize'] ? parseFloat(ingredient['selectedSize'][type]) : ingredient[type];
      return sum + parseFloat(value.toString() || 0)
    }, 0);
    total = total < 1 ? Math.ceil(total) : Math.round(total);
    return total;
  }

  // getTotalBy(value: [], type){
  //   if(!value) {
  //     console.log('ingredientingredient - value');
  //     return;
  //   }
  //   let total = value.reduce((sum, ingredient)=>{
  //     if(!ingredient){
  //       console.log('ingredientingredient - ingredient', sum);
  //       return sum;
  //     }
  //     // const value: any = ingredient[type] < 1 ? Math.ceil(ingredient[type]) : Math.round(ingredient[type]);
  //     const value = ingredient[type];
  //     return sum + parseFloat(value || 0)
  //   }, 0);
  //   total = total < 1 ? Math.ceil(total) : Math.round(total);
  //   return total;
  // }

  getTotalBy1(value: [], type){
    return value.reduce((sum, ingredient)=>{
      const value: any = ingredient[type];
      return sum + parseFloat(value || 0)
    }, 0);
  }

	
	// getCalorieValue(ingredients: []) {
	// 	const totalCal = this.getTotalBy(ingredients, 'calories');
	// 	const totalVal = {};
	// 	let sum = 0;
	// 	for(var [key,ingredient] of Object.entries(ingredients)) {
	// 		sum += parseFloat(ingredient['calories']);
  //   }
	// 	return Math.round(sum);
  // }

  getCalorieValue(ingredients: []){
		const totalCal = this.getTotalBy(ingredients, 'calories');
		const totalVal = {};
    let sum = 0;
    const res = { ...ingredients };
    // console.log('ingredients -typeof', typeof ingredients);
		for(var [key,ingredient] of Object.entries(res)) {
      // console.log('ingredientsingredientsingredients', ingredient);
      if(!ingredient) return sum;
      sum += ingredient['selectedSize'] ? parseFloat(ingredient['selectedSize']['calories']) : parseFloat(ingredient['calories']);
      // console.log('ingredientsingredientsingredients - sum', sum);
    }
    // console.log('ingredientsingredientsingredients', sum);
    if(sum<1){
      Math.ceil(sum);
    }   
    else {
      return Math.round(sum);
    }
  }

	// getCalorieValue(ingredients: []){
	// 	const totalCal = this.getTotalBy(ingredients, 'calories');
	// 	const totalVal = {};
	// 	let sum = 0;
	// 	for(var [key,ingredient] of Object.entries(ingredients)) {
  //     if(!ingredient) return sum;
	// 		sum += parseFloat(ingredient['calories']);
  //   }
  //   if(sum<1){
  //     Math.ceil(sum);
  //   }   
  //   else {
  //     return Math.round(sum);
  //   }
  // }

  getPrecentage(total, consumed){
    return (consumed / total);
  }

  getTotalPrecent(value: [], type){
    const totalCal = this.getTotalBy1(value, 'calories');
    const total = this.getTotalBy1(value, type);
    let res: number;
    switch (type) {
      case 'protein':
      case 'carbs':
        console.log('getTotalPrecent', type ,total * 4, totalCal);
        res =  total * 4 / totalCal;
        break;
      case 'fats':
        console.log('getTotalPrecent', type ,total * 9, totalCal);
        res =  total * 9 / totalCal;
      break;
    }
    return res;
  }
  
  getDishIngredientsNames(ingredients: any){
    return ingredients.map((ingredient)=>{
      return ingredient.name
    });
  }
  
  totalCaloriesOfDay(){

    let totalCalories = 0;
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFats = 0;
    (this.userDaily || []).map((dailyDish:any)=>{
      const { dish } = dailyDish;
      // if(dailyDish.consumed) {
        totalCalories += this.getCalorieValue(dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dish.ingredients);
        totalProtein += this.getTotalBy(dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dish.ingredients, 'protein');
        totalCarbs += this.getTotalBy(dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dish.ingredients, 'carbs');
        totalFats += this.getTotalBy(dish.isIngredientAsDish ? [dailyDish.dish.ingredients[0].selectedSize] : dish.ingredients,'fats');
      // }
    })

    this.dailyConsumption = {
      totalCalories,
      totalProtein,
      totalCarbs,
      totalFats
    }

    this.datasets = {
      'calories': [
        this.dailyConsumption.totalCalories,
        this.dailyGoal.calories - this.dailyConsumption.totalCalories,
      ],
      'protein': [
        this.dailyConsumption.totalProtein,
        this.dailyGoal.protein - this.dailyConsumption.totalProtein,
      ],
      'carbs': [
        this.dailyConsumption.totalCarbs,
        this.dailyGoal.carbs - this.dailyConsumption.totalCarbs,
      ],
      'fats': [
        this.dailyConsumption.totalFats,
        this.dailyGoal.fats - this.dailyConsumption.totalFats,
      ]
    }
  }

  ngOnChanges(propsChanged: SimpleChanges) {
    { 
      console.log('ngOnChangesngOnChanges',propsChanged );
      if(propsChanged['userDaily']) {
        this.totalCaloriesOfDay();
      }
      if(propsChanged['dailyGoal'] && !propsChanged['dailyGoal'].firstChange) {
        this.totalCaloriesOfDay();
      }
    }
  } 

  onDailyDishSelect(dish, event, index){
    dish.consumed = !dish.consumed;
    dish.consumedAt = DateTime.local().toISO();
    this.totalCaloriesOfDay();

    this.onDishStatusUpdate.emit({
      dish, 
      index, 
      dayOfWeek: this.dayOfWeek
    })
  }

  onDailyMenuReorder(dishes, dayOfWeek){
    this.onDailyMenuReorderUpdate.emit({
      dishes,
      dayOfWeek
    })
  }

  onItemRemove(dish, event, index) {

    this.onRemoveDish.emit({ 
      event,
      dish,
      index,
      dayOfWeek: this.dayOfWeek
    });

    setTimeout(()=>{
      this.totalCaloriesOfDay();
    },0)
  }

  // DUPLICATE
  toggleMenu(dish, index) {
    if(!dish.isIngredientAsPortion){
      this.isOpenMenuArray[index] = {
        isOpenMenu: this.isOpenMenuArray[index] ? !this.isOpenMenuArray[index].isOpenMenu: true
      }

      for (const [key, value] of Object.entries(this.isOpenMenuArray)) {
        if(parseInt(key) !== index) {
          this.isOpenMenuArray[key].isOpenMenu = false;
        }
      }
      // if(!dish.isAllowMenu) return;
      // if(this.isAdmin){
      if(this.isOpenMenuArray[index].isOpenMenu){
        this.menuForm = this.createFormArray(dish.ingredients);
        dish.isOpenMenu = !dish.isOpenMenu;
      }
    }
    // };
  }

  // DUPLICATE
  createFormArray(sizeArray: any[]) {
    const res =  
    new FormArray([
      ...sizeArray.map((size)=>{
        console.log('size', size.selectedSize);

        // If dish size is set as portion return
        if(!size.selectedSize && size.size === 'portion') {
          size.selectedSize = {
            calories: size.calories,
            carbs: size.carbs,
            protein: size.protein,
            fats: size.fats,
            size: {
              amountSelected: 'portion',
              selectedType: 'portion'
            }
          } 
        }

        // if no size was defined
        if(!size.selectedSize && size.size === '100 Gram') {
          size.selectedSize = {
            calories: size.calories,
            carbs: size.carbs,
            protein: size.protein,
            fats: size.fats,
            size: {
              amountSelected: size.selectedAmount,
              selectedType: 'custom'
            }
          }
        }

        return new FormGroup({
          selectedSize: new FormControl({
            name: size.selectedSize.size.selectedType,
            amount: size.selectedSize.size.amountSelected
          }),
          ingredient: new FormControl({ 
            id: size.id,
            ...size.selectedSize
          }),
          selectedAmount: new FormControl(size.selectedSize.size.amountSelected, Validators.min(1))
        });
      })
    ])
    return res;
  }

  // DUPLICATE
  updateDishMenu(dish, index) {
    const res = this.menuForm.value;

    const ingredients = res.forEach((menuItem, index)=>{
      // const ingredient = menuItem.ingredient;
      const selectedSize = menuItem.selectedSize;
      if(selectedSize.name === 'custom'){
        selectedSize.amount = menuItem.selectedAmount;

        // support naming
        selectedSize.amountSelected = menuItem.selectedSize.amount;
        selectedSize.selectedType = menuItem.selectedSize.name;
      }
      if(selectedSize.name !== 'custom' && selectedSize.name !== 'portion'){
        selectedSize.amount = menuItem.selectedAmount;

        // support naming
        selectedSize.amountSelected = menuItem.selectedSize.amount;
        selectedSize.selectedType = menuItem.selectedSize.name;
      }

      // UPDATE: recalculate selected size
      if(selectedSize.name !== 'portion'){
        const updatedSelectedSize = {
          calories: (selectedSize.amount / 100) * (dish.dish.ingredients[index].calories), // * 100 / dish.dish.ingredients[index].selectedAmount),
          carbs: (selectedSize.amount / 100) * (dish.dish.ingredients[index].carbs), //* 100 / dish.dish.ingredients[index].selectedAmount),
          fats: (selectedSize.amount / 100) * (dish.dish.ingredients[index].fats), // * 100 / dish.dish.ingredients[index].selectedAmount),
          protein: (selectedSize.amount / 100) * (dish.dish.ingredients[index].protein) // * 100 / dish.dish.ingredients[index].selectedAmount)
        };
        dish.dish.ingredients[index].selectedSize = {
          ...updatedSelectedSize,
          size: selectedSize
        }
      }
      // UPDATE: dish description for ingredient type
      if(dish.dish.isIngredientAsDish){
        dish.dish.shortDescription = selectedSize.name
      }
      // return updatedSelectedSize;
    });

    this.toggleMenu(dish ,index);

    this.totalCaloriesOfDay();

    const value = this.getCalorieValue(dish.isIngredientAsDish ? [dish.dish.ingredients[0].selectedSize] : dish.dish.ingredients);

    debugger;

    this.onDishStatusUpdate.emit({
      dish, 
      index, 
      dayOfWeek: this.dayOfWeek
    })

  }

  // DUPLICATE
  showAmoutWithSizeType(ingredient){
    const { selectedSize: { size: { amountSelected, selectedType } = { amountSelected: '', selectedType: 'portion' } } } = ingredient;
    return `${this.translateService.instant(selectedType)} ${amountSelected} `;; //${this.sizeType}`;
  }

  // DUPLICATE
  // SAVE dish in temp variable
  saveTempDish(dish, index) {
    this.tempDishBeforeChanges[index] = { 
      ...dish,
      ingredients: [ ...dish.ingredients ]
    };
  }

  // Duplicate
  onIngredientSelect(selectedIngredient) {
    return new Promise(async (resolve)=> {
    const selectedItem = selectedIngredient;

    if(selectedItem){
      if(selectedItem.size !== 'portion'){
        const modal = await this.modalController.create({
          component: IngredientAmountSelectionModal,
          backdropDismiss: false,
          cssClass: 'ingredient-amount-selected-modal',
          // presentingElement: await this.modalController.getTop(),
          componentProps: {
            moreSizes: selectedItem.moreSizes
          }
        });
        await modal.present();
        var { data } = await modal.onWillDismiss();
        if(data) {
          selectedItem.selectedAmount = data.amountSelected;
          selectedItem.name = `${selectedItem.name}`; //(${data.amountSelected})`;
          selectedItem.selectedSize = {
            size: data,
            calories: selectedItem.calories * (data.amountSelected / 100),
            protein: selectedItem.protein * (data.amountSelected / 100),
            carbs: selectedItem.carbs * (data.amountSelected / 100),
            fats: selectedItem.fats * (data.amountSelected / 100)
          }
          selectedItem.shortDescription = data.selectedType;
        }
        else{
          return resolve(false);
        }
      }
      if(selectedItem.size === 'portion'){
        data = {
          amountSelected: 'portion',
          selectedType: 'portion'
        }
        selectedItem.selectedAmount = data.amountSelected;
        selectedItem.name = `${selectedItem.name}`;
        selectedItem.selectedSize = {
          size: data,
          calories: selectedItem.calories,
          protein: selectedItem.protein,
          carbs: selectedItem.carbs,
          fats: selectedItem.fats
        };
        selectedItem.shortDescription = selectedItem.isIngredientAsDish ? data.selectedType : selectedItem.shortDescription;
      }
    }

      resolve(selectedItem);
    });
  }

   // DUPLICATE
   onRemoveIngredientAlert(dish, index){

    // TODO: add prompt alert

    // Remove from array
    dish.ingredients.splice(index, 1);

    // Remove from form
    (this.menuForm as FormArray).removeAt(index);
  }

  // Duplicate
  createFormGroup(ingredients: any[]) {
    const ingredient = ingredients[0];
    
    const res =  
    // new FormArray([
    //   ...sizeArray.map((size)=>{
        console.log('size', ingredient.selectedSize);

        // If dish size is set as portion return
        if(!ingredient.selectedSize && ingredient.size === 'portion') {
          ingredient.selectedSize = {
            calories: ingredient.calories,
            carbs: ingredient.carbs,
            protein: ingredient.protein,
            fats: ingredient.fats,
            size: {
              amountSelected: 'portion',
              selectedType: 'portion'
            }
          } 
        }

        // if no size was defined
        if(!ingredient.selectedSize && ingredient.size === '100 Gram') {
          ingredient.selectedSize = {
            calories: ingredient.calories,
            carbs: ingredient.carbs,
            protein: ingredient.protein,
            fats: ingredient.fats,
            size: {
              amountSelected: ingredient.selectedAmount,
              selectedType: 'custom'
            }
          }
        }

        return new FormGroup({
          selectedSize: new FormControl({
            name: ingredient.selectedSize.size.selectedType,
            amount: ingredient.selectedSize.size.amountSelected
          }),
          ingredient: new FormControl({ 
            id: ingredient.id,
            ...ingredient.selectedSize
          }),
          selectedAmount: new FormControl(ingredient.selectedSize.size.amountSelected, Validators.min(1))
        });
    //   })
    // ])
  }

  // Duplicate
  resetMenuChanges(dish, index) {
    dish = this.tempDishBeforeChanges[index];
    this.tempDishBeforeChanges[index] = [];
    this.userDaily[index].dish = dish;
  }

  // DUPLICATE
  async onOpenAddNewIngredientModal(dish, index) {

    const modal = await this.modalController.create({
    component: AddNewIngredientPage,
    cssClass: 'add-new-dish-modal',
    // presentingElement: await this.modalController.getTop(),
    componentProps: {
      ingredients: this.ingredients
    }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data) {
      const { ingredient } = data;
      console.log('onIngredientSelect - data', data);

      const res = await this.onIngredientSelect(ingredient);
      if(res) {
        // this.addedForm = this.addNewDish.contorls.push()

        // this.ingredientsToAdd = [
        //   ...this.ingredientsToAdd,
        //   res
        // ]

        // debugger;

        // this.newDishIngredients.push(ingredient);

        const res2 = this.createFormGroup([res]);

        (this.menuForm as FormArray).push(res2);

        // debugger;
        dish.dish.ingredients.push(ingredient);

        // this.onCaloriesChange(this.newDishIngredients);
      }
    }
  }

  // DUPLICATE
  async saveAsNewDish(dish, index) {

    const modal = await this.modalController.create({
      component: AddNewDishNameModal,
      cssClass: 'my-dish-add-new-name-modal',
      backdropDismiss: false,
      presentingElement: await this.modalController.getTop()
    });
    await modal.present();
    var { data } = await modal.onWillDismiss();
    if(data) {
      dish.dish.name = data.name;
      this.onAddDishToMyDishes.emit({dish});
      this.updateDishMenu(dish,index);
      this.toggleMenu(dish.dish, index);
    }
  }

}
