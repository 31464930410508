import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from "~environments/environment";

import { AppReducers } from './reducers';
import { AppEffectsModules } from './effects';
// import { AddCarListPageModule } from 'src/app/add-car-list/add-car-list.module';

import { FirebaseNgrxUserManagementModule } from '@xtream/firebase-ngrx-user-management';

const optionalImports = [];
if (!environment.production) {
	optionalImports.push(StoreDevtoolsModule.instrument({ maxAge:25 }));
}

@NgModule({
  imports: [
    FirebaseNgrxUserManagementModule,
    StoreModule.forRoot(AppReducers, {
      runtimeChecks: {
        strictActionWithinNgZone: true,
        strictActionImmutability: false,
        strictStateImmutability: false,
        // disabled until https://github.com/ngrx/platform/issues/2109 is resolved
        /* strictActionImmutability: true, */
      }
    }),
    ...optionalImports,
    AppEffectsModules,
    // AddCarListPageModule
  ],
  exports: [],
  providers: []
})
export class CoreStoreModule {}
