import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AppState } from '../reducers';
import { map, tap, withLatestFrom, flatMap, pluck, mergeMap, concatMap, switchMap, mapTo, catchError } from 'rxjs/operators';
import { ActionTypes, loadIngredientsList,loadIngredientItem } from './ingredients.actions';
import * as fromActions from './ingredients.actions';
// import { DateTime } from 'luxon';
import { FirebaseStorageService } from '../../../services/firebase-storage.service';
import { of, from } from 'rxjs';
import { showErrorMessage } from '../core/core.actions';


@Injectable()
export class IngredientsEffects {

  constructor(
    private actions$: Actions,
    private fbStorageService: FirebaseStorageService,
    // private _stringUtil: StringUtil,
    private store: Store<AppState>,
    // private plansApi: PlansApi
  ) { }

  /**
	 * Fetch users list from Firebase store
	 */
  fetchUsersListEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_INGREDIENTS_LIST),
      switchMap(()=>{
        console.log('UsersEffects');
        return this.fbStorageService.getIngredientsList().pipe(
          map((ingredients:any)=>{
            console.warn('== fbStorageService -- getIngredientsList', ingredients);

            const res = ingredients.map((ingredient)=>{
              return { 
                id: ingredient.payload.doc.id,
                ...ingredient.payload.doc.data()
              }
            })

            return loadIngredientsList({ ingredients: res })
          }),
          catchError((error)=>{
            console.log('error', error);
            return of(showErrorMessage(error))
          })
        )
      })
    )
  );

  /**
	 * Handle update ingredient
	 */
  handleIngredientUpdate$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.HANDLE_INGREDIENT_UPDATE),
      switchMap(({ ingredient }: { ingredient: any })=>{
        console.log('handleIngredientUpdate', ingredient);
        ;
        return from(this.fbStorageService.updateIngredient(ingredient.id, ingredient)).pipe(
          tap((ingredient:any)=>{
            console.warn('== fbStorageService -- updateIngredient', ingredient);
            // return loadIngredientItem({ ingredient })
          }),
          catchError((error)=>{
            console.log('error', error);
            return of(showErrorMessage(error))
          })
        )
      })
    ),
    { dispatch: false }
  );

   /**
	 * Handle delete ingredient
	 */
  delete$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.DELETE_INGREDIENT_ITEM),
      switchMap(({ ingredient }: { ingredient: any })=>{
        return from(this.fbStorageService.deleteIngredient(ingredient.id)).pipe(
          tap(()=>{
            console.log('deleteIngredient - suucess');
          }),
          catchError((error)=>{
            return of(showErrorMessage(error))
          })
        )
      })
    ),
    { dispatch: false }
  );

  // @Effect()
  // fetchUsersListEffect2$ = this.actions$.pipe(
  //   ofType(ActionTypes.FETCH_USERS_LIST),
  //   tap(() => { console.log('new fetchUsersListEffect occurred in queue') }),
  //   switchMap(()=>{
  //     console.log('UsersEffects');
  //     return this.fbStorageService.getUsersCollection()
  //     .pipe(
  //       mapTo((users: any) => {
  //         debugger;
  //         console.log(users);
  //         return loadUsersList({ users: [ {name: 'pedro'}]})
  //       })
  //     )
  //   }),
    
  // );

  /**
	 * Handle search query and update results
	 */
  // @Effect()
  // search$ = this.actions$.pipe(
  //   ofType(fromChecksActions.ActionTypes.SEARCH_LIST),
  //   map((action: fromChecksActions.SearchList) => action.payload),
  //   map((query: string) => {
  //     return new fromChecksActions.UpdateResults(query);
  //   })
  // );

  /**
	 * Create check item, Add to Firebase & Load to local store
	 */
  // @Effect({ dispatch: false })
  // addCheckItem$ = this.actions$.pipe(
  //   ofType(fromChecksActions.ActionTypes.ADD_CHECK_ITEM),
  //   map((action: fromChecksActions.AddCheckItem) => action.payload),

  //   // FETCH LIST
  //   withLatestFrom(this.store.pipe(select(getChecksCollection))),

  //   // UPDATE FIREBASE
  //   tap((payload: any) => {
  //     const [itemToAdd, checkCollection] = payload;
  //     const { id, date } = itemToAdd;

  //     const longDate = DateTime.fromISO(date).setLocale('en').toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });

  //     console.log('addCheckItem - itemToAdd', itemToAdd, checkCollection);

  //     if (Object.keys(checkCollection).length > 0) {
  //       this.firebaseService.update_checks_collection(date, { [id]: { ...itemToAdd }}); // update_checks_collection
  //     } else {
  //       this.firebaseService.create_checks_collection(date, { [id]: { ...itemToAdd }});
  //     }
  //   }),
    
  //   // LOAD ITEM
  //   map((payload: any) => {
  //     return new fromChecksActions.LoadCheckItem({ ...payload });
  //   })
  // );

  // @Effect({ dispatch: true })
  // updateCheckItem$ = this.actions$.pipe(
  //   ofType(fromChecksActions.ActionTypes.HANDLE_CHECK_ITEM_UPDATE),
  //   map((action: fromChecksActions.HandleCheckItemUpdate) => action.payload),
  //   // withLatestFrom(
  //   //   this.store.pipe(select(getSelectedCarDetails)),
  //   //   this.store.pipe(select(getSelectedDate)),
  //   //   this.store.pipe(select(getPlanSelectedDate)),
  //   //   (details, carDetails, selectedDate, planSelectedDate) => {
  //   //     return { details, carDetails, selectedDate, planSelectedDate };
  //   //   }
  //   // ),
  //   map((payload: { details, carDetails, selectedDate, planSelectedDate }) => {
      
  //     // LOGIC CODE HERE

  //   }),
  //   concatMap(({ selectedDate, updatedCheckItem }: any) => {
  //     // return new fromCarsActions.(search);
  //     return [
  //       new fromChecksActions.UpdateCheckItem({ selectedDate, updatedCheckItem })
  //     ]
  //   })
  // );

}
