import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { State } from './dishes.reducer';

export const getDishesState = (state: AppState) => {
    return state.dishes
};
export const getDishes = createSelector(
    getDishesState,
    (state: State) => Object.values(state.dishes)
);