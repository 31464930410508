import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { Observable } from 'rxjs';
import { 
  fetchDishesList,
  addDishItem,
  updateDishItem,
  disableDishItem,
  deleteDishItem,
  handleDishItemUpdate
} from '~core/store/dishes/dishes.actions';
import { 
  getDishesState, getDishes
} from '~core/store/dishes/dishes.selectors';

@Injectable()
export class DishesApi {

  dishesState$: Observable<any> = this.store.pipe(select(getDishesState));
  getDishes$: Observable<any> = this.store.pipe(select(getDishes));

  constructor(
    private store: Store<AppState>
  ) { }

  onFetchDishes(_options?: any) {
    this.store.dispatch(fetchDishesList());
  }

  onCreateDish(dish: any) {
    this.store.dispatch(addDishItem(dish));
  }

  onUpdateDish(dish: any) {
    this.store.dispatch(handleDishItemUpdate(dish));
  }

  onDisableDish(dish: any) {
    this.store.dispatch(disableDishItem(dish));
  }

  onDeleteDish(dish: any) {
    this.store.dispatch(deleteDishItem(dish));
  }

}
