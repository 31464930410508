import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { User, auth } from 'firebase/app';
import { ProfileModel } from '../pages/profile/profile.model';

@Injectable()
export class FirebaseAuthService {

  currentUser: User;
  userProviderAdditionalInfo: any;
  redirectResult: Subject<any> = new Subject<any>();

  constructor(
    public angularFire: AngularFireAuth,
    public platform: Platform
  ) {
    console.log('constructor');   

    this.angularFire.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.currentUser = user;
      } else {
        // No user is signed in.
        this.currentUser = null;
      }
    });

    // when using signInWithRedirect, this listens for the redirect results
    this.angularFire.getRedirectResult()
    .then((result) => {
      // result.credential.accessToken gives you the Provider Access Token. You can use it to access the Provider API.
      if (result.user) {
        this.setProviderAdditionalInfo(result.additionalUserInfo.profile);
        this.currentUser = result.user;
        this.redirectResult.next(result);
      }
    }, (error) => {
      this.redirectResult.next({error: error.code});
    });
  }

  ionViewWillEnter(){
    console.log('will enter');   
  }

  getRedirectResult(): Observable<any> {
    return this.redirectResult.asObservable();
  }

  setProviderAdditionalInfo(additionalInfo: any) {
    this.userProviderAdditionalInfo = {...additionalInfo};
  }

  public async getProfileData() {
    let image;
    let { providerData } = await this.getLoggedInUser();

    if (this.userProviderAdditionalInfo) {
      providerData = {...providerData[0], ...this.userProviderAdditionalInfo};
    }

    // Default imgs are too small and our app needs a bigger image
    switch (providerData.providerId) {
      case 'facebook.com':
        image = providerData.photoURL + '?height=400';
        break;
      case 'password':
        image = 'https://s3-us-west-2.amazonaws.com/ionicthemes/otros/avatar-placeholder.png';
        break;
      case 'twitter.com':
        image = providerData.photoURL.replace('_normal', '_400x400');
        break;
      case 'google.com':
        image = providerData.photoURL.split('=')[0];
        break;
      default:
        image = providerData.photoURL;
    }

    const user: ProfileModel = {
      name: providerData.name || providerData.displayName || 'What\'s your name?',
      role: 'How would you describe yourself?',
      image,
      description: providerData.description || 'Anything else you would like to share with the world?',
      phoneNumber: providerData.phoneNumber || 'Is there a number where I can reach you?',
      email: providerData.email || 'Where can I send you emails?',
      provider: (providerData.providerId !== 'password') ? providerData.providerId : 'Credentials'
    }

    return user;
  }

  // Get the currently signed-in user
  getLoggedInUser(): Promise<any> {
    return new Promise(resolve=>{
      this.angularFire.onAuthStateChanged((user)=>{
        if (user) {
          // User is signed in.
          this.currentUser = user;
        } else {
          // No user is signed in.
          this.currentUser = null;
        };
        resolve(this.currentUser);
      });
    })
  }

  signOut(): Observable<any> {
    return from(this.angularFire.signOut());
  }

  signInWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFire.signInWithEmailAndPassword(email, password);
  }

  signUpWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFire.createUserWithEmailAndPassword(email, password);
  }

  socialSignIn(providerName: string, scopes?: Array<string>): Promise<any> {
    const provider = new auth.OAuthProvider(providerName);

    // add any permission scope you need
    if (scopes) {
      scopes.forEach(scope => {
        provider.addScope(scope);
      });
    }

    if (this.platform.is('desktop')) {
      return this.angularFire.signInWithPopup(provider);
    } else {
      // web but not desktop, for example mobile PWA
      return this.angularFire.signInWithRedirect(provider);
    }
  }

  signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    // const scopes = ['user_birthday'];
    return this.socialSignIn(provider.providerId);
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    return this.socialSignIn(provider.providerId, scopes);
  }

  signInWithTwitter() {
    const provider = new auth.TwitterAuthProvider();
    return this.socialSignIn(provider.providerId);
  }

  // register() {
  //   this.angularFire.createUserWithEmailAndPassword(this.user.email, this.user.password)
  //   .then(data => {
  //       let alert = this.alertCtrl.create({
  //           title: 'Success',
  //           message: 'Email verification has been sent',
  //           buttons: ['OK']
  //       })
  //       alert.present()
  //       this.navCtrl.pop()
  //       this.emailVerification()
  //   })
  //   .catch(err => {
  //       console.log(err)
  //       this.error = err.message
  //   })
  // }

  emailVerification() {
    return this.angularFire.currentUser.then((user) => {
      return user.sendEmailVerification();
    });
  }
}
