import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { Observable } from 'rxjs';
import {  
  fetchUser,
  addUserItem,
  updateUserItem,
  disableUserItem,
  deleteUserItem,
  setAsAdminUser,
  handleUserItemUpdate,
} from '~core/store/user/user.actions';
import { 
  getUserState,
  getIsAdmin,
  getUserStats,
  getUserWeekly,
  getUser,
  getIsUserAdmin,
  getUserDailyGoal
} from '~core/store/user/user.selectors';
import { 
  fetchUserWeekly, setSelectedDate
} from '~core/store/user-daily/user-daily.actions';

@Injectable()
export class UserDailyApi {

  userState$: Observable<any> = this.store.pipe(select(getUserState));
  userData$: Observable<any> = this.store.pipe(select(getUser));
  isAdmin$: Observable<any> = this.store.pipe(select(getIsUserAdmin));
  userStats$: Observable<any> = this.store.pipe(select(getUserStats));
  userWeekly$: Observable<any> = this.store.pipe(select(getUserWeekly));
  dailyGoal$: Observable<any> = this.store.pipe(select(getUserDailyGoal));

  constructor(
    private store: Store<AppState>
  ) { }

  onFetchUser(userId: string) {
    this.store.dispatch(fetchUser({userId}));
  }

  onCreateUser(user: any) {
    this.store.dispatch(addUserItem(user));
  }

  onUpdateUser(user: any) {
    this.store.dispatch(handleUserItemUpdate(user));
  }

  onDisableUser(user: any) {
    this.store.dispatch(disableUserItem(user));
  }

  onDeleteUser(user: any) {
    this.store.dispatch(deleteUserItem(user));
  }

  setAsAdmin(isAdmin: boolean) {
    this.store.dispatch(setAsAdminUser({isAdmin}));
  }

  setSelectedDate(selectedDateId: string) {
    this.store.dispatch(setSelectedDate({selectedDateId}));
  }

  onFetchUserDaily(user: any) {
    this.store.dispatch(fetchUserWeekly({user}));
  }

}
