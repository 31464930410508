import { Injectable } from '@angular/core';
import { UserModel, UserWeekly } from '../modals/user.modal';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class FirebaseStorageService {
  userListRef: AngularFireList<any>;
  userRef: AngularFireObject<any>;

  usersCollectionRef: AngularFirestoreCollection<any>;
  ingredientsCollectionRef: AngularFirestoreCollection<any>;
  dishesCollectionRef: AngularFirestoreCollection<any>;

  constructor(
    private db: AngularFireDatabase,
    private afs: AngularFirestore
  ) {
    this.usersCollectionRef = this.afs.collection<any>('users');
    this.ingredientsCollectionRef = this.afs.collection<any>('ingredients', ref => ref.orderBy('createdAt','desc'));
    this.dishesCollectionRef = this.afs.collection<any>('dishes', ref => ref.orderBy('createdAt','desc'));

    // Configure cache size
    // firebase.firestore().settings({
    //   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    // });  
    // Enabling offline data
    // firebase.firestore().enablePersistence()
  }

  // Create
  createBooking(user: any) {
    const res = this.usersCollectionRef.add({
        name: user.name,
        nameToSearch: user.name.toLowerCase(),
        surname: user.name,
        role: user.role,
        avatar: user.image
    });
    return res;
  }

  // Get Single
  getBooking(id: string) {
    this.userRef = this.db.object('/appointment/' + id);
    return this.userRef;
  }

  // Get List
  getBookingList() {
    this.userListRef = this.db.list('/appointment');
    return this.userListRef;
  }

  // Update
  updateBooking(id, user: UserModel) {
    return this.userRef.update({
      name: user.name,
      email: user.email,
      mobile: user.phoneNumber
    })
  }

  // Delete
  deleteBooking(id: string) {
    this.userRef = this.db.object('/appointment/' + id);
    this.userRef.remove();
  }

  // * COLLECTION * //

  // Get Single
  getUserDocument(id: string) {
    const userRef = this.usersCollectionRef.doc(id).valueChanges();
    return userRef;
  }

  // Get List
  getUsersCollection(): Observable<any> {
    console.log('getUsersCollection');
    const userListRef = this.afs.collection<any>('users').valueChanges({ idField: 'id' });
    const productsCollectionRef = this.afs.collection('users'); 
    const products = productsCollectionRef.valueChanges();
    console.log('userListRef');
    // debugger;
    return userListRef;
  }

  // Create
  createUser(user: any) {
    // return this.userListRef.push({
    //   name: user.name,
    //   email: user.email,
    //   mobile: user.phoneNumber
    // })
    const res = this.usersCollectionRef.doc(user.uid).set({
        uid: user.uid,
        createAt: new Date().toISOString(),
        newUser: true,
        name: user.displayName ? user.displayName : user.email.split('@')[0],
        nameToSearch: user.displayName ? user.displayName.toLowerCase() : user.email.split('@')[0],
        surname: '',
        lastname: '',
        role: 'user',
        email: user.email,
        avatar: user.photoURL
    });
    return res;
  }

  // Update
  updateUser(id, user: any): Promise<any> {
    return new Promise((resolve, reject)=> {
      this.usersCollectionRef.doc(id).set({...user}).then(
        (res)=>{
          resolve('Success');
        },
        (onError)=>{
          reject('onError');
        },
      )
    })
  }

  // Delete
  deleteUser(id: string) {
    const userRef = this.usersCollectionRef.doc(id).delete();
    return userRef;
  }

  // * INGREDIENTS COLLECTION * //

  getIngredientsList() {
    const res = this.ingredientsCollectionRef.snapshotChanges();
    return res;
  }

  getIngredient(id: string) {
    return this.ingredientsCollectionRef.doc(id);
  }

  createIngredient(item: any) {
    return this.ingredientsCollectionRef.add({...item})
  }

  updateIngredient(id: string, item: any) {
    // debugger;
    if(id) {
      return this.ingredientsCollectionRef.doc(id).set({ ...item })
    }
    else {
      return this.createIngredient({ ...item })
    }
  }

  deleteIngredient(id: string): Promise<any> {
    return this.ingredientsCollectionRef.doc(id).delete()
  }

  // * DISHES COLLECTION * //

  getDishesList() {
    return this.dishesCollectionRef.snapshotChanges();

    // return this.dishesCollectionRef.valueChanges({ idField: 'id' }).pipe(map((dishes: any)=>{
    //   return dishes.map((dish)=>{
    //     // debugger;
    //     dish.ingredients = dish.ingredients.map((ingredientRef)=>{
    //       const ref = ingredientRef;
    //       return ref;
    //     })
    //     // dish.ingredients = [ dish.ingredients[0].valueChanges() ];
    //     return dish;
    //   })
    //   // return dish
    // }))
    // flatMap((feeds: any) => combineLatest(feeds)))
  }

  getDish(id: string) {
    return this.dishesCollectionRef.doc(id);
  }

  createDish(item: any) {
    return this.dishesCollectionRef.add({...item})
  }

  updateDish(id: string, item: any) {
    if(id) {
      return this.dishesCollectionRef.doc(id).set({ ...item })
    }
    else {
      return this.createDish({ ...item })
    }
  }

  deleteDish(id: string): Promise<any> {
    return this.dishesCollectionRef.doc(id).delete();
  }

  // * COLLECTION * //

  // Get Single
  getDocument(collection: string , docId: string) {
    const userRef = this.afs.collection<any>(collection).doc(docId);
    return userRef;
  }

  // Get List
  getCollection(collection: string): Observable<any> {
    const userListRef = this.afs.collection<any>(collection).valueChanges({ idField: 'id' });
    return userListRef;
  }

  // Update
  updateDocument(collection: string ,docId: string, item: UserModel) {
    return this.afs.collection<any>(collection).doc(docId).update({...item})
  }

  // Delete
  deleteDocument(collection: string, docId: string) {
    const userRef = this.afs.collection<any>(collection).doc(docId).delete();
    return userRef;
  }

  // * USER_WEEKLY COLLECTION * // 

  // Get Single
  getUserDaily(collection: string , userId: string, dateId: string): Observable<any> {
    const userRef = this.usersCollectionRef.doc(userId).collection<any>('user_weekly').doc(dateId).valueChanges();
    return userRef;
  }

  // Get List
  getUserWeekly(collection: string, userId: string, dateId: string): Observable<any> {
    // const userListRef = this.afs.collection<any>(collection).valueChanges({ idField: 'id' });
    const userListRef = this.usersCollectionRef.doc(userId).collection<any>('user_weekly').doc(dateId).valueChanges();
    return userListRef;
  }

  // Update Single Date - Day
  updateUserDaily(collection: string ,userId: string, dateId: string, item: any): Promise<void> {
    return this.usersCollectionRef.doc(userId).collection<any>('user_weekly').doc(dateId).set({...item});
  }

  // Delete selected Date - Day
  deleteUserDaily(collection: string, userId: string, dateId: string): Promise<void>  {
    const userRef = this.usersCollectionRef.doc(userId).collection<any>('user_weekly').doc(dateId).delete();
    return userRef;
  }

  // * USER_DISHES COLLECTION * // 

  // Get List
  getUserDishes(userId: string, dateId?: string): Observable<any> {
    // const userListRef = this.afs.collection<any>(collection).valueChanges({ idField: 'id' });
    const userListRef = this.usersCollectionRef.doc(userId).collection<any>('user_dishes').doc('user_dishes').valueChanges();
    return userListRef;
  }

  // updateUserDishes(collection: any[] ,userId: string): Promise<void> {
  //   return this.usersCollectionRef.doc(userId).collection<any>('user_dishes').doc('user_dishes').update({
  //     dishes: { 
  //       ['']: { ...collection }
  //     }
  //   });
  // }

  // Update List
  updateUserDish(dishes: any ,userId: string): Promise<void> {
    return this.usersCollectionRef.doc(userId).collection<any>('user_dishes').doc('user_dishes').set({
      dishes: dishes
    });
  }

  // Delete selected Date - Day
  deleteUserDishesCollection(collection: any[], userId: string, dateId: string): Promise<void>  {
    const userRef = this.usersCollectionRef.doc(userId).collection<any>('user_dishes').doc('user_dishes').delete();
    return userRef;
  }

}