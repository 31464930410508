import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { State, IDateEntities } from './user.reducer';
import { DateTime } from 'luxon';
import dayjs from 'dayjs'

export const getUserState = (state: AppState) => {
    return state.user
};
export const getIsAdmin = createSelector(
    getUserState,
    (state: State) => state.isAdmin
)
export const getUser = createSelector(
    getUserState,
    (state: State) => { 
        if(state.selectedUser) {
            return state.selectedUser
        }
        {
            return state.user
        }
    }
)
export const getIsUserAdmin = createSelector(
    getUserState,
    (state: any = {}) => {
        if(state.user) {
            return state.user.role === 'admin'
        }
        else {
            return false
         }
    }
)
export const getUserStats = createSelector(
    getUserState,
    (state: State) => state.userStats
)

export const getUserWeekly = createSelector(
    getUser,
    (user: any = {}) => {
        const { userWeekly = { } } = user;

        const date = {
            'Sun': dayjs().day(0).format('YYYY-MM-DD'), 
            'Mon': dayjs().day(1).format('YYYY-MM-DD'), 
            'Tue': dayjs().day(2).format('YYYY-MM-DD'), 
            'Wed': dayjs().day(3).format('YYYY-MM-DD'), 
            'Thu': dayjs().day(4).format('YYYY-MM-DD'), 
            'Fri': dayjs().day(5).format('YYYY-MM-DD'), 
            'Sat': dayjs().day(6).format('YYYY-MM-DD'), 
        }

        const resetMenu =(date, _numOfWeek)=>{
            const day = date.subtract(7, 'days').format('YYYY-MM-DD');

            const selectedDate = userWeekly[day] || userWeekly[_numOfWeek] || [];

            selectedDate.forEach((dish)=>{
                dish.consumed = false;
            })

            return [ ...selectedDate];
        }
        // TODO: remove
        const userWeeklyArray = {
            [date['Sun']]: userWeekly[date['Sun']] || resetMenu(dayjs().day(0),0),
            [date['Mon']]: userWeekly[date['Mon']] || resetMenu(dayjs().day(1),1),
            [date['Tue']]: userWeekly[date['Tue']] || resetMenu(dayjs().day(2),2),
            [date['Wed']]: userWeekly[date['Wed']] || resetMenu(dayjs().day(3),3),
            [date['Thu']]: userWeekly[date['Thu']] || resetMenu(dayjs().day(4),4),
            [date['Fri']]: userWeekly[date['Fri']] || resetMenu(dayjs().day(5),5),
            [date['Sat']]: userWeekly[date['Sat']] || resetMenu(dayjs().day(6),6),
        }
        return userWeeklyArray;
    }
)

export const getUserDailyGoal = createSelector(
    getUser,
    (user: any = {}) => {
        const { userGoal = { 
            calories: 0,
            protein: 0,
            carbs: 0,
            fats: 0
        } } = user;
        // console.log('useruseruseruseruseruser', userGoal);
        return userGoal
    }
)

export const getMyDishes = createSelector(
    getUserState,
    (state: State) => {
        const res = state.userDishes
        // console.log('=====>>>>>>>> userDishes', res);
        return res
    }

)