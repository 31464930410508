import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError, tap, pluck } from 'rxjs/operators';
import { ActionTypes } from './core.actions';
import { of } from 'rxjs';
import { ToastMessages, ToastService } from 'src/app/providers/toast.provider';


@Injectable()
export class CoreEffects {

  constructor(
    private actions$: Actions,
    private toastService: ToastService
  ) { }


  /**
	 * Show error message
	 */
  ShowSuccessMessage$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.SHOW_SUCCESS_MESSAGE),
      pluck('toastMessage'),
      tap((toastMessage: ToastMessages)=>{
        this.toastService.presentToast(toastMessage);
      })
    ),
    { dispatch: false }
  );

  /**
	 * Show error message
	 */
  ShowErrorMessage$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.SHOW_ERROR_MESSAGE),
      pluck('toastMessage'),
      tap((toastMessage: ToastMessages)=>{
        this.toastService.presentToast(toastMessage);
      })
    ),
    { dispatch: false }
  );

}
