import { Action, createAction, props } from '@ngrx/store';

/**
 * For each action in an action group, make a simple
 * enum object for all of this group's action types
 */
export enum ActionTypes {
  FETCH_INGREDIENTS_LIST = '[Users] FETCH_INGREDIENTS_LIST',
  LOAD_INGREDIENTS_LIST = '[Users] LOAD_INGREDIENTS_LIST',

  ADD_INGREDIENT_ITEM = '[Users] ADD_INGREDIENT_ITEM',
  LOAD_INGREDIENT_ITEM = '[Users] LOAD_INGREDIENT_ITEM',
  HANDLE_INGREDIENT_UPDATE = '[Checks] HANDLE_INGREDIENT_UPDATE',
  UPDATE_INGREDIENT_ITEM = '[Users] UPDATE_INGREDIENT_ITEM',
  DISABLE_INGREDIENT_ITEM = '[Users] DISABLE_INGREDIENT_ITEM',
  DELETE_INGREDIENT_ITEM = '[Users] DELETE_INGREDIENT_ITEM',

  SEARCH_LIST = '[Users] SEARCH_LIST',
  UPDATE_RESULTS = '[Users] UPDATE_RESULTS',

  SET_SELECTED_INGREDIENT = '[Users] SET_SELECTED_INGREDIENT',
  FETCH_SELECTED_INGREDIENT = '[Users] FETCH_SELECTED_INGREDIENT'
}

/**
 * Every action in comprised of at least a type and an optinal
 * payload. Expressing actions as classes enables powerful type
 * checking in reducer functions.
 */
export const fetchIngredientsList = createAction(
  ActionTypes.FETCH_INGREDIENTS_LIST
);

export const loadIngredientsList = createAction(
  ActionTypes.LOAD_INGREDIENTS_LIST,
  props<{ingredients: any}>()
);

export const addIngredientItem = createAction(
  ActionTypes.ADD_INGREDIENT_ITEM,
  props<{ingredient: any}>()
);

export const loadIngredientItem = createAction(
  ActionTypes.LOAD_INGREDIENT_ITEM,
  props<{ingredient: any}>()
);

export const handleIngredientItemUpdate = createAction(
  ActionTypes.HANDLE_INGREDIENT_UPDATE,
  props<{ingredient: any}>()
);

export const updateIngredientItem = createAction(
  ActionTypes.UPDATE_INGREDIENT_ITEM,
  props<{ingredient: any}>()
);

export const disableIngredientItem = createAction(
  ActionTypes.DISABLE_INGREDIENT_ITEM,
  props<{ingredient: any}>()
);

export const deleteIngredientItem = createAction(
  ActionTypes.DELETE_INGREDIENT_ITEM,
  props<{ingredient: any}>()
);

export const searchList = createAction(
  ActionTypes.SEARCH_LIST,
  props<{query: any}>()
);

export const updateResults = createAction(
  ActionTypes.UPDATE_RESULTS,
  props<{ingredients: any}>()
);

export const setSelectedIngredient = createAction(
  ActionTypes.SET_SELECTED_INGREDIENT,
  props<{ingredient: any}>()
);

export const fetchSelectedItem = createAction(
  ActionTypes.FETCH_SELECTED_INGREDIENT,
  props<{ingredient: any}>()
);