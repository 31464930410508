import { createAction, props } from '@ngrx/store';
import { ToastMessages } from 'src/app/providers/toast.provider';

/**
 * For each action in an action group, make a simple
 * enum object for all of this group's action types
 */
export enum ActionTypes {
  SHOW_SUCCESS_MESSAGE = '[Core] SHOW_SUCCESS_MESSAGE',
  SHOW_ERROR_MESSAGE = '[Core] SHOW_ERROR_MESSAGE'
}

/**
 * Every action in comprised of at least a type and an optinal
 * payload. Expressing actions as classes enables powerful type
 * checking in reducer functions.
 */
export const showSuccessMessage = createAction(
  ActionTypes.SHOW_SUCCESS_MESSAGE,
  props<{toastMessage: ToastMessages}>()
);

export const showErrorMessage = createAction(
  ActionTypes.SHOW_ERROR_MESSAGE,
  props<{toastMessage: ToastMessages}>()
);