import {Injectable} from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export enum LoadingMessages {
  USER_INFO_UPDATE = 'USER_INFO_UPDATE',
}

@Injectable()
export class LoadingService {

  loading: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController,
    private translateService: TranslateService
  ) {}

  async presentLoading(loadingMessage: LoadingMessages) {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant(loadingMessage),
      spinner: 'lines-small',
      duration: 3000
    });
    await this.loading.present();

    const { role, data } = await this.loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  async dismissLoading() {
    if(this.loading) {
      this.loading.dismiss();
    }
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      spinner: null,
      duration: 5000,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      backdropDismiss: true
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed with role:', role);
  }
}
