import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import {
  ActionTypes, 
  loadDishesList,
  loadDishItem,
} from './dishes.actions';
import * as fromActions from './dishes.actions';
import { FirebaseStorageService } from '../../../services/firebase-storage.service';
import { of, from } from 'rxjs';
import { showErrorMessage } from '../core/core.actions';


@Injectable()
export class DishesEffects {

  constructor(
    private actions$: Actions,
    private fbStorageService: FirebaseStorageService,
    // private _stringUtil: StringUtil,
    private store: Store<AppState>,
    // private plansApi: PlansApi
  ) { }

  /**
	 * Fetch dishes list from Firebase store
	 */
  fetchDishesListEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_DISHES_LIST),
      switchMap(()=>{
        return this.fbStorageService.getDishesList().pipe(
          map((dishes:any)=>{
            const res = dishes.map((dish)=>{
              return { 
                id: dish.payload.doc.id,
                ...dish.payload.doc.data()
              }
            })
            return loadDishesList({ dishes: res })
          }),
          catchError((error)=>{
            console.log('error', error);
            return of(showErrorMessage(error))
          })
        )
      })
    )
  );

  /**
	 * Hadle update user
	 */
  handleDishUpdate$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.HANDLE_DISH_UPDATE),
      switchMap(({ dish }: { dish: any })=>{
        return from(this.fbStorageService.updateDish(dish.id, dish)).pipe(
          tap((dish:any)=>{
            console.log('updateIngredient - success', dish);
          }),
          catchError((error)=>{
            console.log('updateIngredient - error', dish);
            return of(showErrorMessage(error))
          })
        )
      })
    ),
    { dispatch: false }
  );

  /**
	 * Handle delete ingredient
	 */
  HandleDishDelete$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.DELETE_DISH_ITEM),
      switchMap(({ dish }: { dish: any })=>{
        return from(this.fbStorageService.deleteDish(dish.id)).pipe(
          tap(()=>{
            console.log('deleteDish - suucess');
          }),
          catchError((error)=>{
            return of(showErrorMessage(error))
          })
        )
      })
    ),
    { dispatch: false }
  );

  // /**
	//  * Fetch user's personal dishes list from Firebase store
	//  */
  // fetchUserDishesEffect$ = createEffect(()=>
  //   this.actions$.pipe(
  //     ofType(ActionTypes.FETCH_USER_DISHES),
  //     switchMap(({ userId })=>{
  //       return this.fbStorageService.getUserDishes(userId).pipe(
  //         map((userDishes:any = {})=>{
  //           const { myDishes = [] } = userDishes;
  //           const res = myDishes.map((dish)=>{
  //             return { 
  //               id: dish.payload.doc.id,
  //               ...dish.payload.doc.data()
  //             }
  //           })
  //           return loadUserDishes({ dishes: res })
  //         }),
  //         catchError((error)=>{
  //           console.error('fetchUserDishesEffect - error', error);
  //           return of(loadUserDishes(error))
  //         })
  //       )
  //     })
  //   )
  // );


}
