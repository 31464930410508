import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { map, switchMap, catchError, tap, withLatestFrom, concatMap } from 'rxjs/operators';
import { ActionTypes, loadUser, loadUserWeekly } from './user-daily.actions';
import * as fromActions from './user-daily.actions';
// import { DateTime } from 'luxon';
import { FirebaseStorageService } from '../../../services/firebase-storage.service';
import { from, of } from 'rxjs';
import { setSelectedUser } from '../../../admin/store/admin/admin.actions';
import { UserApi } from '~core/api/user.api';
import { getSelectedDate } from './user-daily.selectors';
import { showErrorMessage, showSuccessMessage } from '../core/core.actions';
import { LoadingMessages, LoadingService } from '../../../providers/loading.provider';
import { ToastMessages } from '../../../providers/toast.provider';
import dayjs from 'dayjs'


@Injectable()
export class UserDailyEffects {

  constructor(
    private actions$: Actions,
    private fbStorageService: FirebaseStorageService,
    private store: Store<AppState>,
    private loadingService: LoadingService
  ) { }

  /**
	 * Fetch users list from Firebase store
	 */
  UserEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.ADD_USER_ITEM),
      switchMap((userData:any)=>{
        const { additionalUserInfo: { isNewUser, providerId }, user  } = userData
        
        // Create new User
        if(isNewUser){
          return this.fbStorageService.createUser(user);
        }

        // return this.fbStorageService.updateIngredient(user);
        // return this.fbStorageService.getUserDocument(user).pipe(
        //   map((user:any)=>{
        //     console.log('getUsersCollection', user);
        //     return setSelectedUser({user})
        //   }),
        //   catchError((error)=>{
        //     console.log('error', error);
        //     return of(setSelectedUser(error))
        //   })
        // )
      })
    ),
    { dispatch: false }
  );

  /**
	 * Update user state
	 */
  updateUserEffect$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.HANDLE_USER_ITEM_UPDATE),
      tap(()=>{
        this.loadingService.presentLoading(LoadingMessages.USER_INFO_UPDATE);
      }),
      switchMap((userData:any)=>{
        userData['newUser'] = false;
        return from(this.fbStorageService.updateUser(userData.uid, userData)).pipe(
          tap((toastMessage:any)=>{
            this.loadingService.dismissLoading();
          }),
          map((toastMessage:any)=>{
            return showSuccessMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_SUCCESSFULLY})
          }),
          catchError((error)=>{
            this.loadingService.dismissLoading();
            console.log('HANDLE_USER_ITEM_UPDATE - error', error)
            return of(showErrorMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_FAILURE}))
          })
        )
      })
    ),
    // { dispatch: false }
  );

  /**
	 * Update user weekly on Firebase store
	 */
  FetchUserWeekly$ = createEffect(()=>
    this.actions$.pipe(
      ofType(ActionTypes.FETCH_USER_DAILY),
      withLatestFrom(this.store.pipe(select(getSelectedDate))),
      switchMap(([{user}, selectedDate]: [any, any])=>{        

        return from(this.fbStorageService.getUserWeekly('', user.uid, selectedDate)).pipe(
          tap((toastMessage:any)=>{
            // this.loadingService.dismissLoading();
          }),
          concatMap((userDaily)=>{
            if(!userDaily){
              const previusWeekDailyDate = dayjs(selectedDate, 'YYYY-MM-DD').subtract(7, 'day').format('YYYY-MM-DD');
              // debugger;
              {
                const dayNum = dayjs(selectedDate, 'YYYY-MM-DD').format('d')
                const selectedDaily = user.userWeekly[previusWeekDailyDate] || user.userWeekly[dayNum];
                this.fbStorageService.updateUserDaily('', user.uid, previusWeekDailyDate, selectedDaily);
              }
              return this.fbStorageService.getUserWeekly('', user.uid, previusWeekDailyDate).pipe(
                map((previusWeekDayDaily)=>{
                  return previusWeekDayDaily;
                  // return loadUserWeekly({userDaily:previusWeekDayDaily})
                })
              )
            }
          }),
          map((userDaily:any)=>{
            // return showSuccessMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_SUCCESSFULLY})
            // debugger;
            if(!userDaily){
              const previusWeekDailyDate = dayjs(selectedDate, 'YYYY-MM-DD').subtract(7, 'day').format('YYYY-MM-DD');
              // debugger;
              {
                const dayNum = dayjs(selectedDate, 'YYYY-MM-DD').format('d')
                const selectedDaily = user.userWeekly[previusWeekDailyDate] || user.userWeekly[dayNum];
                this.fbStorageService.updateUserDaily('', user.uid, previusWeekDailyDate, selectedDaily);
              }
              // return from(this.fbStorageService.getUserWeekly('', user.uid, previusWeekDailyDate)).pipe(
              //   map((previusWeekDayDaily)=>{
              //     previusWeekDayDaily;
              //     return loadUserWeekly({userDaily:previusWeekDayDaily})
              //   })
              // )
            }
            else {
              return loadUserWeekly({userDaily: userDaily});
            }
          }),
          catchError((error)=>{
            // this.loadingService.dismissLoading();
            console.log('HANDLE_USER_ITEM_UPDATE - error', error)
            return of(showErrorMessage({toastMessage: ToastMessages.USER_INFO_UPDATED_FAILURE}))
          })
        )

        // return this.fbStorageService.updateIngredient(user);
        // return this.fbStorageService.getUserDocument(user).pipe(
        //   map((user:any)=>{
        //     console.log('getUsersCollection', user);
        //     return setSelectedUser({user})
        //   }),
        //   catchError((error)=>{
        //     console.log('error', error);
        //     return of(setSelectedUser(error))
        //   })
        // )
      })
    )
    // { dispatch: false }
  );

}
