import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dayOfWeek'
})
export class DateOfWeekPipe implements PipeTransform {

  transform(dayOfWeek: any, args?: any): any {  
    let numOfWeekDay;
    if(args === 'toNumOfWeek'){
      switch (dayOfWeek) {
        case 'Sun':
          numOfWeekDay = 0
          break;
        case 'Mon':
          numOfWeekDay = 1
          break;
        case 'Tue':
          numOfWeekDay = 2
          break;
        case 'Wed':
          numOfWeekDay = 3
          break;
        case 'Thu':
          numOfWeekDay = 4
          break;
        case 'Fri':
          numOfWeekDay = 5
          break;
        case 'Sat':
          numOfWeekDay = 6
          break;
      }
    }
    return numOfWeekDay;
  }

}
