import * as fromActions from './user-daily.actions';
import { createReducer, on } from '@ngrx/store';

export interface ICheckItem {
  id: string;
  type: string;
  status: string;
  number: string;
  date: string;
  amount: number;
}

export interface ICheckEntities {
  [key: string]: ICheckItem;
}

export interface IDateEntities {
  [key: string]: ICheckEntities;
}

export interface State {
  isAdmin: boolean;
  user: any;
  selectedUser: any;
  userStats: any;

  userDaily: any;
  userWeekly: any;
  selectedDateId: string;
}

const initialState: State = {
  isAdmin: false,
  user: undefined,
  selectedUser: undefined,
  userStats: undefined,

  userDaily: {},
  userWeekly: {},
  selectedDateId: undefined
};

export const rootReducer = createReducer(
  initialState, 

  on(fromActions.setSelectedDate, (state, { selectedDateId }) => {
    return { 
      ...state,
      selectedDateId
    }
  }),

  on(fromActions.loadUserWeekly, (state, { userDaily }) => {
    // debugger;
    return { 
      ...state,
      userDaily
    }
  })
)

// export function reducer(
//   state = initialState,
//   action: any // fromActions.Actions
// ): State {
//   const { ActionTypes } = fromActions;

//   switch (action.type) {

//     case ActionTypes.LOAD_USERS_LIST: {
//       const { payload: checksList } = action;

//       return {
//         ...state,
//         checksCollection: {
//           ...state.checksCollection,
//           ...checksCollection
//         }
//       };
//     }

//     default: {
//       return state;
//     }
//   }
// }
