import { createReducer, on } from '@ngrx/store';
import * as fromActions from './ingredients.actions';

export interface ICheckItem {
  id: string;
  type: string;
  status: string;
  number: string;
  date: string;
  amount: number;
}

export interface ICheckEntities {
  [key: string]: ICheckItem;
}

export interface IDateEntities {
  [key: string]: ICheckEntities;
}

export interface State {
  ingredients: any;
}

const initialState: State = {
  ingredients: {},
};

export const rootReducer = createReducer(
  initialState,
  
  on(fromActions.loadIngredientsList, (state, { ingredients }) => {
    return { 
      ...state, 
      ingredients: { 
        // ...state.ingredients,
        ...ingredients
      }
    }
  }),

  on(fromActions.loadIngredientItem, (state, { ingredient }) => {
    return { 
      ...state, 
      ingredients: { 
        ...state.ingredients,
        ...ingredient
      }
    }
  }),

  // on(ApiSuccess, (state, action) => ({ selectedMockData: action.data, error: null })),
)