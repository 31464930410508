import { NgModule } from '@angular/core';
import { CoreStoreModule } from "./store/store.module";
import { APP_APIS } from './api';

@NgModule({
  imports: [
    CoreStoreModule
  ],
  exports: [
    CoreStoreModule
  ],
  providers: [
    ...APP_APIS
  ]
})
export class AppCoreModule {}